import { ChangeEvent, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Dropdown, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/common/SearchBox';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import {
  fetchNodeInstances,
  fetchNodeTypes,
} from '../../../../redux/slices/node_instancesSlice';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useSearchParams } from 'react-router-dom';
import DatePickerComponent from './DatePicker';
import RefreshIconButton from './RefreshButton';
import ExpandableRowContent from './ExpandableRowContent';
interface NodeInstance {
  node_instance_id: number;
  node_type: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  status?: string;
  scheduled_start_datetime?: string;
  scheduled_start_timezone?: string;
  queue_name?: string;
  sent_to_worker_name?: string;
  schedule?:any;
}

const NodeInstances = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const queueId = searchParams.get('queue_id');
  const queueName = searchParams.get('queue_name');
  const sentToWorkerId = searchParams.get('sent_to_worker_id');
  const sentToWorkerName = searchParams.get('sent_to_worker_name');
  const scheduledStartTimezone = searchParams.get('scheduled_start_timezone');
  const nodeDefinitionIds = searchParams.get('node_definition_id')
    ? searchParams
        .get('node_definition_id')
        ?.split(',')
        .map((id) => id.trim())
    : [];
  const nodeInstancesIds = searchParams.get('node_instance_id')
    ? searchParams
        .get('node_instance_id')
        ?.split(',')
        .map((id) => id.trim())
    : [];
  const status = searchParams.get('status') ? [searchParams.get('status')] : [];

  const nodeInstances = useSelector((state: any) => state.nodeInstances.nodes);
  const nodeTypes = useSelector((state: any) => state.nodeInstances.nodeTypes);

  const pagination = useSelector(
    (state: any) => state.nodeInstances.pagination
  );
  const error = useSelector((state: any) => state.nodeInstances.error);

  const { currentPage, totalPages, totalItems, pageSize } = pagination;

  const initialSearchTerm = searchParams.get('search') || '';
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [sortColumn, setSortColumn] = useState<string>('node_instance_id');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const getStartAndEndOfMonth = () => {
    const start = null;
    const end = null;
    return { startDate: start, endDate: end };
  };

  const [dateRanges, setDateRanges] = useState<{
    [key: string]: { startDate: Date; endDate: Date; initialized: boolean };
  }>({
    created_at: { ...getStartAndEndOfMonth(), initialized: true },
    scheduled_start_datetime: { ...getStartAndEndOfMonth(), initialized: true },
    scheduled_start_datetime_local: {
      ...getStartAndEndOfMonth(),
      initialized: true,
    },
  });

  const allTypes = nodeTypes.map((node: NodeInstance) => node.node_type);
  const uniqueTypes: string[] = allTypes.filter(
    (type: string, index: number) => allTypes.indexOf(type) === index
  );
  const limitOptions = [10, 25, 50, 100];

  const handleAction = (action:any,rowData:any) => {
    const nodeInstanceId = rowData.node_instance_id
    if(action === 'Show Instance'){
      const url = `/instance_viewer/${nodeInstanceId}`;
      window.open(url, '_blank', 'noopener, noreferrer');
    }
  }

  // action dropdown start
  const ActionDropdown = ({ rowData }) => {
    const actions = ['Cancel', 'Resubmit', 'Show Instance'];

    return (
      <Dropdown>
        <Dropdown.Toggle variant="phoenix-secondary">
          <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {actions.map((action, index) => (
            <Dropdown.Item key={index} href="#" onClick={()=> handleAction(action, rowData)}>
              {action}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  // action dropdown end

  const handleDateChange = (name: string, startDate: Date, endDate: Date) => {
    setDateRanges((prev) => ({
      ...prev,
      [name]: {
        startDate: startDate,
        endDate: endDate,
        initialized: false,
      },
    }));
  };

  // dropdown start
  const [selectedTypes, setSelectedTypes] = useState<string[]>(['All']);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [filters, setFilters] = useState({
    node_instance_id: '',
    node_definition_id: '',
    name: '',
    status: '',
    scheduled_start_datetime_from: '',
    scheduled_start_datetime_to: '',
    scheduled_start_timezone: '',
    queue_name: '',
    sent_to_worker_name: '',
  });

  const [showDropdownType, setShowDropdownType] = useState(false);
  const [showDropdownStatus, setShowDropdownStatus] = useState(false);

  const dropdownTypeRef = useRef<HTMLDivElement>(null);
  const dropdownStatusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchNodeTypes());
  }, [dispatch]);

  const handleSelect = (type: string) => {
    setSelectedTypes((prevState) => {
      if (type === 'All') {
        return prevState.includes('All') ? [] : ['All'];
      }
  
      if (prevState.includes('All')) {
        return [type]; 
      }
  
      return prevState.includes(type)
        ? prevState.filter((item) => item !== type)
        : [...prevState, type];
    });
  };
  

  const dropdownTitle =
    selectedTypes.length == 1
      ? `Filter by Type (${selectedTypes.length}) - ${selectedTypes.join(', ')}`
      : selectedTypes.length > 1
        ? `Filter by Type (${selectedTypes.length})`
        : 'Filter by Type';

  // dropdown end

  useEffect(() => {
    // Get the URL search parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Update state based on the URL parameters
    setFilters((prevFilters) => ({
      node_instance_id:
        queryParams.get('node_instance_id') || prevFilters.node_instance_id,
      node_definition_id:
        queryParams.get('node_definition_id') || prevFilters.node_definition_id,
      name: queryParams.get('name') || prevFilters.name,
      status: queryParams.get('status') || prevFilters.status,
      scheduled_start_datetime_from:
        queryParams.get('scheduled_start_datetime_from') ||
        prevFilters.scheduled_start_datetime_from,
      scheduled_start_datetime_to:
        queryParams.get('scheduled_start_datetime_to') ||
        prevFilters.scheduled_start_datetime_to,
      scheduled_start_timezone:
        queryParams.get('scheduled_start_timezone') ||
        prevFilters.scheduled_start_timezone,
      queue_name: queryParams.get('queue_name') || prevFilters.queue_name,
      sent_to_worker_name:
        queryParams.get('sent_to_worker_name') ||
        prevFilters.sent_to_worker_name,
    }));
  }, []);

  const formatWithBrowserTimezone = (date: Date) => {
    const offsetMinutes = date.getTimezoneOffset();

    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
    const offsetMinutesRemainder = Math.abs(offsetMinutes % 60);

    const sign = offsetMinutes > 0 ? '-' : '+';

    const localDate = new Date(date.getTime() - offsetMinutes * 60 * 1000);

    const formattedDate = localDate
      .toISOString()
      .split('T')
      .join(' ')
      .split('Z')[0];

    return `${formattedDate}${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutesRemainder).padStart(2, '0')}`;
  };

  const handleClickOutside = (e) => {
    if (dropdownStatusRef.current && !dropdownStatusRef.current.contains(e.target)) {
      setShowDropdownStatus(false);
    }
    if (dropdownTypeRef.current && !dropdownTypeRef.current.contains(e.target)) {
      setShowDropdownType(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside the dropdown
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchNodeInstances({
        page: currentPage,
        limit: pageSize,
        sortColumn,
        sortOrder,
        search: searchTerm.length > 3 ? searchTerm : undefined,
        queue_id: queueId ? parseInt(queueId) : undefined,
        queue_name: queueName || undefined,
        sent_to_worker_id: sentToWorkerId
          ? parseInt(sentToWorkerId)
          : undefined,
        sent_to_worker_name: sentToWorkerName || undefined,
        status: status || undefined,
        node_definition_id: nodeDefinitionIds.toString(),
        created_at_from: !dateRanges.created_at.initialized
          ? dateRanges.created_at.startDate.toISOString()
          : '',
        created_at_to: !dateRanges.created_at.initialized
          ? dateRanges.created_at.endDate.toISOString()
          : '',
        scheduled_start_datetime_from: !dateRanges.scheduled_start_datetime
          .initialized
          ? dateRanges.scheduled_start_datetime.startDate.toISOString()
          : '',
        scheduled_start_datetime_to: !dateRanges.scheduled_start_datetime
          .initialized
          ? dateRanges.scheduled_start_datetime.endDate.toISOString()
          : '',
        // scheduled_start_datetime_local_from: !dateRanges.created_at.initialized  ? dateRanges.scheduled_start_datetime_local.startDate.toLocaleDateString : '',
        // scheduled_start_datetime_local_to:!dateRanges.created_at.initialized ? dateRanges.scheduled_start_datetime_local.endDate.toLocaleDateString : '',
        scheduled_start_datetime_local_from: dateRanges
          .scheduled_start_datetime_local.startDate
          ? formatWithBrowserTimezone(
              dateRanges.scheduled_start_datetime_local.startDate
            )
          : '',
        scheduled_start_datetime_local_to: dateRanges
          .scheduled_start_datetime_local.endDate
          ? formatWithBrowserTimezone(
              dateRanges.scheduled_start_datetime_local.endDate
            )
          : '',
        node_instance_id: nodeInstancesIds.toString(),
        scheduled_start_timezone: scheduledStartTimezone,
        name: filters.name
      })
    );
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchTerm,
    sortColumn,
    sortOrder,
    dateRanges,
    filters,
  ]);

  const handleFilterChange = (key: string, value: string) => {
    // Prepare the value for 'created_at' and 'scheduled_start_datetime' to handle 'from' and 'to' if applicable
    let newFilters = { ...filters };

    if (key === 'created_at' || key === 'scheduled_start_datetime') {
      if (value.includes(',')) {
        // Split into 'from' and 'to' if a comma is present
        const [from, to] = value.split(',');
        newFilters = {
          ...newFilters,
          [`${key}_from`]: from.trim(),
          [`${key}_to`]: to.trim(),
        };
      } else {
        newFilters = { ...newFilters, [`${key}_from`]: value };
      }
    } else {
      newFilters[key] = key === 'status' ? [value] : value;
    }

    const filteredFilters = Object.fromEntries(
      Object.entries(newFilters).filter(
        ([_, v]) => v && (Array.isArray(v) ? v.length > 0 : v.length > 0)
      )
    );
    console.log('wtf',filteredFilters)
    // Set filters to state
    setFilters(newFilters);

    // Set searchParams with only filters that have actual values
    setSearchParams(filteredFilters);

    // Dispatch the action with the filtered newFilters
    dispatch(
      fetchNodeInstances({
        ...filteredFilters, // Use the filtered values
        // Ensure 'status' is always an array if it's present in the filters
        status: Array.isArray(filteredFilters.status)
          ? filteredFilters.status
          : [filteredFilters.status],
        page: 1, // Reset page to 1 with the new filter
        limit: pageSize,
        search: searchTerm,
        sortColumn,
        sortOrder,
      })
    );
  };

  const handleSort = (column: string) => {
    setSortOrder((prevOrder) =>
      sortColumn === column && prevOrder === 'asc' ? 'desc' : 'asc'
    );
    setSortColumn(column);

    const newSearchParams = new URLSearchParams(window.location.search); // Get the current URL search params
    newSearchParams.set('sortOrder', sortOrder);
    newSearchParams.set('sortColumn', column);

    window.history.replaceState(null, '', '?' + newSearchParams.toString());
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setSearchTerm(value.length > 1 ? value : '');

        // Update URL query parameter
        if (value.length > 1) {
          searchParams.set('search', value);
        } else {
          searchParams.delete('search');
        }
        setSearchParams(searchParams);
      }, 300)
    );
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, []);

  const handlePageChange = (page: number) => {
    dispatch(
      fetchNodeInstances({
        page: currentPage+1,
        limit: pageSize,
        sortColumn: 'node_instance_id',
        sortOrder: 'asc',
        search: searchTerm,
      })
    );
  };

  const handlePageSizeChange = (limit: number) => {
    dispatch(
      fetchNodeInstances({
        page: 1,
        limit,
        sortColumn: 'node_instance_id',
        sortOrder: 'asc',
        search: searchTerm,
      })
    );
  };

  const statusOptions = [
    'scheduled',
    'pending',
    'hold',
    'waiting',
    'running',
    'completed',
    'failed',
    'cancelled',
    'skipped',
    'terminated',
  ];

  const handleNodeTypeChange = (node_type: string) => {
    if (node_type === 'All') {
      const allTypes = ['All', ...uniqueTypes];
      dispatch(
        fetchNodeInstances({
          page: 1,
          type: allTypes,
          limit: pageSize,
          search: searchTerm,
          sortColumn: 'node_instance_id',
          sortOrder: 'asc',
        })
      );
      return;
    }

    if (!selectedTypes.includes(node_type)) {
      const newSelectedTypes = [...selectedTypes, node_type];
      dispatch(
        fetchNodeInstances({
          page: 1,
          type: newSelectedTypes,
          limit: pageSize,
          search: searchTerm,
          sortColumn: 'node_instance_id',
          sortOrder: 'asc',
        })
      );
    }
  };

  const handleStatusChange = (statusOption: string | string[]) => {
    if (!statusOption || typeof statusOption === 'object') {
      const newStatuses = selectedStatuses.length === 0 ? statusOptions : [];
      setSelectedStatuses(newStatuses);

      dispatch(
        fetchNodeInstances({
          page: currentPage,
          limit: pageSize,
          sortColumn: 'node_instance_id',
          sortOrder: 'asc',
          search: searchTerm,
          status: newStatuses.length > 0 ? newStatuses : undefined, // Send an empty array or undefined
        })
      );
      return;
    }

    let updatedStatuses: string[] = [];

    setSelectedStatuses((prevSelected) => {
      if (prevSelected.includes(statusOption as string)) {
        updatedStatuses = prevSelected.filter(
          (status) => status !== statusOption
        );
      } else {
        updatedStatuses = [...prevSelected, statusOption as string];
      }

      return updatedStatuses;
    });

    dispatch(
      fetchNodeInstances({
        page: currentPage,
        limit: pageSize,
        sortColumn: 'node_instance_id',
        sortOrder: 'asc',
        search: searchTerm,
        status: updatedStatuses.length > 0 ? updatedStatuses : undefined, // Send updated list of statuses
      })
    );
  };
  
  const columns: ColumnDef<NodeInstance>[] = [
    {
      accessorKey: 'node_instance_id',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('node_instance_id')}
            style={{ cursor: 'pointer' }}
          >
            Instance ID
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.node_instance_id || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'node_instance_id',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'node_definition_id',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('node_definition_id')}
            style={{ cursor: 'pointer' }}
          >
            Definition ID
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.node_definition_id || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'node_definition_id',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('name')}
            style={{ cursor: 'pointer' }}
          >
            Name
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.name || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'name',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      id: 'status',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('status')}
            style={{ cursor: 'pointer' }}
          >
            Status
          </span>
          <div onClick={(e) => e.stopPropagation()} className="my-2">
            <Dropdown ref={dropdownStatusRef} show={showDropdownStatus}>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-status"
                style={{ borderColor: '#cbd0dd', background: 'white' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdownStatus((prev) => !prev);
                }}
              >
                {t('Status')}:{' '}
                {selectedStatuses.length == 1
                  ? selectedStatuses.join(', ')
                  : selectedStatuses.length > 1
                    ? `(${selectedStatuses.length})`
                    : t('All')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleStatusChange([])}>
                  {selectedStatuses.length === 0 && '✓'} {t('All')}
                </Dropdown.Item>
                {statusOptions.map((statusOption) => (
                  <Dropdown.Item
                    key={statusOption}
                    onClick={() => handleStatusChange(statusOption)}
                  >
                    {selectedStatuses.includes(statusOption) && '✓'}{' '}
                    {statusOption}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const status = row.original.status;
        return status || 'Status not available';
      },
    },
    {
      accessorKey: 'created_at',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('created_at')}
            style={{ cursor: 'pointer' }}
          >
            Created At
          </span>
          <div onClick={(e) => e.stopPropagation()} className="my-2">
            <DatePickerComponent
              name="created_at"
              initialStartDate={dateRanges.created_at.startDate}
              initialEndDate={dateRanges.created_at.endDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      ),
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString(),
    },
    {
      accessorKey: 'scheduled_start_datetime',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('scheduled_start_datetime')}
            style={{ cursor: 'pointer' }}
          >
            Scheduled Start Datetime
          </span>
          <div onClick={(e) => e.stopPropagation()} className="my-2">
            <DatePickerComponent
              name="scheduled_start_datetime"
              initialStartDate={dateRanges.scheduled_start_datetime.startDate}
              initialEndDate={dateRanges.scheduled_start_datetime.endDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'scheduled_start_timezone',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('scheduled_start_timezone')}
            style={{ cursor: 'pointer' }}
          >
            Scheduled Start Timezone
          </span>
          <div onClick={(e) => e.stopPropagation()} className="my-2">
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.scheduled_start_timezone || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'scheduled_start_timezone',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      id: 'scheduled_start_datetime_local',
      accessorKey: 'scheduled_start_datetime',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('scheduled_start_datetime')}
            style={{ cursor: 'pointer' }}
          >
            Scheduled Start (Local Time)
          </span>
          <div onClick={(e) => e.stopPropagation()} className="my-2">
            <DatePickerComponent
              name="scheduled_start_datetime_local"
              initialStartDate={
                dateRanges.scheduled_start_datetime_local.startDate
              }
              initialEndDate={dateRanges.scheduled_start_datetime_local.endDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const datetime = row.original.scheduled_start_datetime;
        return datetime
          ? new Date(datetime).toLocaleString(undefined, {
              timeZoneName: 'short',
            })
          : '';
      },
    },
    {
      accessorKey: 'schedule',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('schedule')}
            style={{ cursor: 'pointer' }}
          >
            Schedule
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150, visibility:'hidden' }}
              className="my-2"
              disabled={true}
            />
          </div>
        </div>
      ),
      cell: ({ row }) => {
        const { interval, unit } = row.original?.schedule ?? {};
        return (
          <span>
            {interval && unit ? `Every ${interval} ${unit}${interval > 1 ? 's' : ''}` : ''}
          </span>
        );
      }
    },
    {
      accessorKey: 'queue_name',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('queue_name')}
            style={{ cursor: 'pointer' }}
          >
            Queue
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.queue_name || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'queue_name',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'sent_to_worker_name',
      header: () => (
        <div>
          <span
            onClick={() => handleSort('worker_name')}
            style={{ cursor: 'pointer' }}
          >
            Worker
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              style={{ width: 150 }}
              defaultValue={filters.sent_to_worker_name || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFilterChange(
                    'sent_to_worker_name',
                    (e.target as HTMLInputElement).value
                  );
                }
              }}
              className="my-2"
            />
          </div>
        </div>
      ),
    },
    {
      id: 'actions',
      header: () => <span>Actions</span>,
      cell: ({ row }) => <ActionDropdown rowData={row.original}/>,
    },
  ];

  const renderExpandableContent = useCallback((row: any) => (
    <ExpandableRowContent instanceId={row.original.node_instance_id} />
  ), []);

  const table = useAdvanceTable({
    data: nodeInstances,
    columns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          {error && <Alert variant="danger">{t('error-generic')}</Alert>}
          <div className="d-flex gap-2 mb-3">
            <Dropdown ref={dropdownTypeRef} show={showDropdownType}>
              <Dropdown.Toggle
                as={Button}
                variant="secondary"
                id="node-type-filter-dropdown"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdownType((prev) => !prev);
                }}
              >
                {dropdownTitle}
              </Dropdown.Toggle>
              {showDropdownType && (
                <Dropdown.Menu>
                  {['All', ...uniqueTypes].map((type: string) => (
                    <Dropdown.Item
                      key={type}
                      onClick={() => {
                        handleSelect(type);
                        handleNodeTypeChange(type);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {selectedTypes.includes(type) && '✓'} {type}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
            </Dropdown>
            <RefreshIconButton />
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto"
            />
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-limit">
                {t('limit')}: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[1, 10, 25, 50, 100].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    onClick={() => handlePageSizeChange(limit)}
                  >
                    {limit}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                hover: true,
                className:
                  'phoenix-table fs-9 mb-0 border-top border-translucent',
              }}
              expandableRowRenderer={renderExpandableContent}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter
              navBtn
              pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={(page) => handlePageChange(page)}
            />
          </AdvanceTableProvider>
        </Col>
      </Row>
      <Row>
        <Col>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-limit">
              {t('limit')}: {pageSize}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {limitOptions.map((limit) => (
                <Dropdown.Item
                  key={limit}
                  onClick={() => handlePageSizeChange(limit)}
                  active={limit === pageSize ? true : false}
                >
                  {limit}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  );
};

export default NodeInstances;
