import { Form } from 'react-bootstrap';

const SqlPlus = ({ state, onChange }) => {
  const { script, run_as_user, database_user, nr_of_lines_to_load } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-script">
        <Form.Label>Script</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          name="script"
          value={script}
          onChange={(e) => onChange(e, true)}
          aria-label="With textarea"
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-run_as_user">
        <Form.Label>Run as user</Form.Label>
        <Form.Control
          type="text"
          name="run_as_user"
          value={run_as_user}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-database_user">
        <Form.Label>Database user</Form.Label>
        <Form.Control
          type="text"
          name="database_user"
          value={database_user}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-nr_of_lines_to_load">
        <Form.Label>Number of lines to load</Form.Label>
        <Form.Control
          type="text"
          name="nr_of_lines_to_load"
          value={nr_of_lines_to_load}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default SqlPlus;
