import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Form, Button, Alert } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment-timezone';
import { fetchCustomerNodeDefinitionById } from '../../../../redux/slices/customer_node_definitionsSlice';
import { useTranslation } from 'react-i18next';
import { submitNode } from '../../../../redux/slices/nodeSubmitSlice';
import { getQueues } from '../../../../redux/slices/lists/queuesSlice';
import 'react-datetime/css/react-datetime.css';

const INITIAL_SUBMIT_BODY = {
    name: '',
    queue_name: '',
    scheduled_start_datetime: moment().format(),
    scheduled_start_timezone: moment.tz.guess(),
    parameters: [],
    schedule: {}
};

const NodeSubmit: React.FC = () => {
    const { node_definition_id } = useParams<{ node_definition_id: string }>();
    const [selectedTab, setSelectedTab] = useState('submit');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const nodeDefinition = useSelector((state: any) => state.customerNodeDefinitions.nodes.find((node: any) => node.node_definition_id === parseInt(node_definition_id)));
    const queues = useSelector((state: any) => state.listOfQueues?.queues || []);
    const rootNodeInstanceId = useSelector((state: any) => state.nodeSubmit.rootNodeInstanceId);
    const [submitBody, setSubmitBody] = useState(INITIAL_SUBMIT_BODY);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (node_definition_id) {
            dispatch(fetchCustomerNodeDefinitionById(parseInt(node_definition_id)));
        }
    }, [dispatch, node_definition_id]);

    useEffect(() => {
        if (nodeDefinition) {
            setSubmitBody((prev) => ({
                ...prev,
                name: nodeDefinition.name,
                queue_name: 'default'
            }));
        }
    }, [nodeDefinition]);

    useEffect(() => {
        dispatch(getQueues());
    }, [dispatch]);

    const handleTabChange = (key: string | null) => {
        if (key) {
            setSelectedTab(key);
        }
    };

    const handleParameterChange = (index: number, value: string) => {
        setSubmitBody((prev) => {
            const newParameters = [...prev.parameters];
            const paramName = nodeDefinition.parameters[index].name;
            const existingParamIndex = newParameters.findIndex(param => param.name === paramName);

            if (existingParamIndex !== -1) {
                newParameters[existingParamIndex].value = value;
            } else {
                newParameters.push({ name: paramName, value });
            }

            return { ...prev, parameters: newParameters };
        });
    };

    const handleDatetimeChange = (datetime: moment.Moment | string) => {
        if (typeof datetime === 'string') return;
        setSubmitBody((prev) => ({
            ...prev,
            scheduled_start_datetime: datetime.format()
        }));
    };

    const handleTimezoneChange = (e: React.ChangeEvent<unknown>) => {
        const event = e as React.ChangeEvent<HTMLSelectElement>;
        const timezone = event.target.value;
        setSubmitBody((prev) => ({
            ...prev,
            scheduled_start_timezone: timezone,
            scheduled_start_datetime: moment(prev.scheduled_start_datetime).tz(timezone).format()
        }));
    };

    const handleQueueChange = (e: React.ChangeEvent<unknown>) => {
        const event = e as React.ChangeEvent<HTMLSelectElement>;
        const queueName = event.target.value;
        setSubmitBody((prev) => ({
            ...prev,
            queue_name: queueName
        }));
    };

    const handleSubmit = async () => {
        const bodyToSubmit = { ...submitBody };
        if (submitBody.queue_name === 'default') {
            delete bodyToSubmit.queue_name;
        }
        try {
            const resultAction = await dispatch(submitNode(bodyToSubmit));
            if (submitNode.fulfilled.match(resultAction)) {
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error submitting node:', error);
        }
    };

    if (!nodeDefinition) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {showAlert && (
                <Alert variant="outline-success" onClose={() => setShowAlert(false)} dismissible>
                     Succesfully submited. <a href={`/automation/node-instances?node_instance_id=${rootNodeInstanceId}`} target="_parent">View Node Instance</a>
                </Alert>
            )}
            <h3>Submit Node Definition id: {node_definition_id}</h3>
            <Tabs
                id="node-submit-tabs"
                activeKey={selectedTab}
                onSelect={handleTabChange}
                style={{ flexGrow: 0 }}
                className="mb-2 border-bottom"
                variant="underline"
            >
                <Tab eventKey="submit" title="Submit">
                    <div style={{ flexGrow: 1 }}>
                        <Form.Group controlId="scheduled_start_datetime">
                            <Form.Label>Scheduled Start Datetime</Form.Label>
                            <Datetime
                                value={moment(submitBody.scheduled_start_datetime)}
                                onChange={handleDatetimeChange}
                                utc={false}
                                inputProps={{ placeholder: 'Click to select date and time' }}
                                dateFormat="YYYY-MM-DD"
                                timeFormat="HH:mm"
                            />
                        </Form.Group>
                        <Form.Group controlId="scheduled_start_timezone">
                            <Form.Label>Scheduled Start Timezone</Form.Label>
                            <Form.Control
                                as="select"
                                value={submitBody.scheduled_start_timezone}
                                onChange={handleTimezoneChange}
                            >
                                {moment.tz.names().map((timezone) => (
                                    <option key={timezone} value={timezone}>
                                        {timezone}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="queue_name">
                            <Form.Label>Queue</Form.Label>
                            <Form.Control
                                as="select"
                                value={submitBody.queue_name}
                                onChange={handleQueueChange}
                            >
                                <option value="default">Default ({nodeDefinition.default_queue_name})</option>
                                {queues.map((queue: any) => (
                                    <option key={queue.queue_name} value={queue.queue_name}>
                                        {queue.queue_name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </div>
                </Tab>
                <Tab eventKey="parameters" title="Parameters">
                    <div style={{ flexGrow: 1 }}>
                        {nodeDefinition.parameters.map((param: any, index: number) => (
                            <Form.Group key={param.name} controlId={`param-${param.name}`}>
                                <Form.Label>{param.name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={param.default_expression}
                                    value={submitBody.parameters.find(p => p.name === param.name)?.value || ''}
                                    onChange={(e) => handleParameterChange(index, e.target.value)}
                                />
                            </Form.Group>
                        ))}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default NodeSubmit;