import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
export interface SearchBoxProps extends FormControlProps {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  formClassName?: string;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
}

const SearchBox = ({
  placeholder = 'Search',
  size,
  className,
  inputClassName,
  formClassName,
  style,
  ...rest
}: SearchBoxProps) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    if (searchTerm.trim()) {
      // Update the URL with the search term
      navigate(`?search=${encodeURIComponent(searchTerm.trim())}`, { replace: true });
    } else {
      // Handle case where search term is empty, if needed
      console.log('Search term is empty');
    }
  };
  
  return (
    <div className={classNames('search-box', className)} style={style}>
      <form className={classNames('position-relative', formClassName)} onSubmit={handleSearch}>
        <Form.Control
          type="search"
          placeholder={placeholder}
          onChange={(e) => setSearchTerm(e.target.value)} 
          className={classNames('search-input search', inputClassName)}
          size={size}
          {...rest}
        />
        <FontAwesomeIcon icon={faSearch} className="search-box-icon" />
      </form>
    </div>
  );
};

export default SearchBox;
