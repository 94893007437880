import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWorkers, createWorker, updateWorker, deleteWorker } from '../../../../redux/slices/workersSlice';
import Button from 'components/base/Button';
import { Modal, Form, Dropdown } from 'react-bootstrap';
import { fetchNodeTypes } from '../../../../redux/slices/node_instancesSlice';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
interface WorkerFormProps {
  worker_id?: number;
  worker_name?: string;
  worker_secret?: string;
  pre_execution_script?: { type: string; script: string }[]
}

const ListWorkers: React.FC = () => {
  const dispatch = useDispatch();
  const { items: workers, status } = useSelector((state: any) => state.workers);
  const [showModal, setShowModal] = useState(false);
  const [formValues, setFormValues] = useState<WorkerFormProps>({ worker_id: undefined, worker_name: '', worker_secret: '', pre_execution_script: [], });
  const [deleteConfirmId, setDeleteConfirmId] = useState<number | null>(null);
  const pagination = useSelector(
    (state: any) => state.customerNodeDefinitions.pagination
  );

  const nodeTypes = useSelector((state: any) => state.nodeInstances.nodeTypes); // Adjust selector path based on your state

  useEffect(() => {
    dispatch(fetchNodeTypes());
  }, [dispatch]);

  useEffect(()=>{
  },[nodeTypes])

  // Add a new pre-execution script
  const handleAddScript = () => {
    setFormValues({
      ...formValues,
      pre_execution_script: [...formValues.pre_execution_script, { type: '', script: '' }],
    });
  };

  // Remove a pre-execution script
  const handleRemoveScript = (index: number) => {
    setFormValues({
      ...formValues,
      pre_execution_script: formValues.pre_execution_script.filter((_, i) => i !== index),
    });
  };

  // Update a pre-execution script field
  const handleScriptChange = (index: number, field: keyof { type: string; script: string }, value: string) => {
    const updatedScripts = [...formValues.pre_execution_script];
    updatedScripts[index][field] = value;
    setFormValues({ ...formValues, pre_execution_script: updatedScripts });
  };

  // End of preexecution scripts

  useEffect(() => {
    if (status === null) {
      dispatch(fetchWorkers());
    }
  }, [dispatch, status]);

  // Open the modal for creating or editing
  const handleShowModal = (worker?: WorkerFormProps) => {
    if (worker) {
      setFormValues({ ...worker, worker_secret: '', pre_execution_script: worker.pre_execution_script || [] }); // For editing, initialize worker_secret as empty
    } else {
      setFormValues({ worker_id: undefined, worker_name: '', worker_secret: '', pre_execution_script: [],      }); // For new worker
    }
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setFormValues({ worker_id: undefined, worker_name: '', worker_secret: '' });
  };

  // Handle form submission for create or update
  const handleSaveWorker = () => {
    const { worker_id, worker_name, worker_secret, pre_execution_script } = formValues;
  
    if (worker_id) {
      // Update worker, include pre_execution_script if it has been modified
      const payload = {
        worker_id,
        worker_name,
        ...(worker_secret ? { worker_secret } : {}),
        pre_execution_script,  // Ensure this is included
      };
      dispatch(updateWorker(payload));
    } else {
      // Create new worker with pre_execution_script
      dispatch(createWorker({ worker_name, worker_secret, pre_execution_script }));
    }
    handleCloseModal();
  };

  // Confirm delete modal
  const handleDeleteConfirm = (worker_id: number) => {
    setDeleteConfirmId(worker_id);
  };

  const confirmDeleteWorker = () => {
    if (deleteConfirmId) {
      dispatch(deleteWorker(deleteConfirmId));
    }
    setDeleteConfirmId(null);
  };

  const handleAction = (action: string, rowData: WorkerFormProps) => {
    const worker = rowData
    if(action === 'edit_worker'){
      handleShowModal(worker)
    }

    if(action === 'delete_worker'){
      handleDeleteConfirm(worker.worker_id)
    }
  };

    const columns: ColumnDef<WorkerFormProps>[] = [
      {
        accessorKey: 'worker_id',
        header: 'Worker ID',
      },
      {
        accessorKey: 'worker_name',
        header: 'Worker Name',
      },
      {
        accessorKey: 'last_seen',
        header: 'Last Seen',
      },
      {
        accessorKey: 'status',
        header: 'Status',
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => {
          const rowData = row.original;
          const actions = [
            { label: 'Edit', action: 'edit_worker' },
            { label: 'Delete', action: 'delete_worker' },
          ];
  
          return (
            <Dropdown>
              <Dropdown.Toggle variant="phoenix-secondary" size="sm">
                <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {actions.map((actionItem, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleAction(actionItem.action, rowData)}
                  >
                    {actionItem.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ];

      const table = useAdvanceTable({
        data: workers,
        columns,
        pageSize:100,
        pagination: true,
        sortable: true,
        selection: false,
      });
    

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button variant="phoenix-primary" onClick={() => handleShowModal()}>
          New
        </Button>
      </div>
      {status === 'loading' && <h1>Loading...</h1>}
      {status === 'failed' && <h1>Error loading workers data.</h1>}
      {status === 'succeeded' && (
        <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{
            size: 'sm',
            hover: true,
            className:
              'phoenix-table fs-9 mb-0 border-top border-translucent',
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        </AdvanceTableProvider>
      )}

      {/* Worker Form Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{formValues.worker_id ? 'Edit Worker' : 'New Worker'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formWorkerName">
              <Form.Label>Worker Name</Form.Label>
              <Form.Control
                type="text"
                value={formValues.worker_name || ''}
                onChange={(e) => {
                  const value = e.target.value.replace(/\s/g, ''); // Remove any spaces
                  setFormValues({ ...formValues, worker_name: value });
                }}
                placeholder="Enter worker name"
              />
            </Form.Group>
            <Form.Group controlId="formWorkerSecret">
              <Form.Label>Worker Secret</Form.Label>
              <Form.Control
                type="password"
                value={formValues.worker_secret || ''}
                onChange={(e) => setFormValues({ ...formValues, worker_secret: e.target.value })}
                placeholder="Enter new worker secret"
              />
              <Form.Text className="text-muted">Leave blank to keep the existing secret.</Form.Text>
            </Form.Group>
            <Form.Group controlId="formPreExecutionScripts">
              <Form.Label>Pre-Execution Scripts</Form.Label>
              {formValues.pre_execution_script?.map((script, index) => (
                <div key={index} className="d-flex flex-column gap-2 mb-3 border p-3 rounded">
                  <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    value={script.type}
                    onChange={(e) => handleScriptChange(index, 'type', e.target.value)}
                  >
                    <option value="">Select Type</option>
                    {nodeTypes?.map((type) => (
                      <option key={type.node_type} value={type.node_type}>
                        {type.node_type}
                      </option>
                    ))}
                    {/* <option>Test</option> */}
                  </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Script</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={script.script}
                      onChange={(e) => handleScriptChange(index, 'script', e.target.value)}
                      placeholder="Enter script here"
                    />
                  </Form.Group>
                  <Button variant="danger" onClick={() => handleRemoveScript(index)}>Remove</Button>
                </div>
              ))}
              <Button className='mx-4' variant="phoenix-primary" onClick={handleAddScript}>Add Script</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
          <Button variant="phoenix-primary" onClick={handleSaveWorker}>{formValues.worker_id ? 'Save Changes' : 'Create Worker'}</Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteConfirmId !== null} onHide={() => setDeleteConfirmId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this worker?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteConfirmId(null)}>Cancel</Button>
          <Button variant="danger" onClick={confirmDeleteWorker}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListWorkers;
