import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQueues } from '../../../../../../../redux/slices/lists/queuesSlice';

const Queues = ({ selectedValue, defaultValue = null, onChange }) => {
  const dispatch = useDispatch();
  const { queues } = useSelector((state: any) => state?.listOfQueues);

  useEffect(() => {
    const fetchData = async () => {
      if (queues.length) return;

      try {
        const response = await dispatch(getQueues());
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const defaultQueue = defaultValue
    ? queues.find((item) => item.queue_name === defaultValue)?.queue_name
    : null;

  return (
    <Form.Group className="mb-2" controlId="formProperties">
      <Form.Label>Default Queue</Form.Label>
      <Form.Select
        aria-label="queue-select"
        name="default_queue_name"
        value={selectedValue}
        onChange={(e) => onChange(e)}
      >
        {defaultValue && <option value="">Default: {defaultQueue}</option>}
        {queues.map((queue, index) => {
          const { queue_name } = queue;

          return (
            <option key={index} value={queue_name}>
              {queue_name}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default Queues;
