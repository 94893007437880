// store.ts (Rename your store.js to store.ts)
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer'; // Ensure rootReducer is typed
import rootSaga from './sagas';
import { useDispatch } from 'react-redux';
import { composeWithDevTools } from '@redux-devtools/extension';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const disableReduxLogger = process.env.REACT_APP_DISABLE_REDUX_LOGGER;

// Logger needs to be the last middleware in chain, otherwise it will log thunk and promise, not actual actions
const middlewareList = [
    sagaMiddleware,
    ...(disableReduxLogger === 'true' ? [] : [logger]),
];

// Configure the store
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: true })
            .concat(middlewareList),
    devTools: composeWithDevTools,
});

// Run the root saga
sagaMiddleware.run(rootSaga);

// Derive the RootState type from the rootReducer
export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

// Create a typed `useAppDispatch` hook
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
