import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  Alert,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import {
  updateCustomerNodeDefinition,
  fetchCustomerNodeDefinitionById,
  fetchCustomerNodeDefinitionAuditTrail,
} from '../../../../../redux/slices/customer_node_definitionsSlice';

// ReactFlow
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
} from 'reactflow';

// Tabs
import Parameters from './components/tabs/Parameters';
import Properties from './components/tabs/Properties';
import AuditTrail from './components/tabs/AuditTrail';

// Editors
import Bash from './components/editors/Bash';
import Flow from './components/editors/Flow';
import Rest from './components/editors/Rest';
import Sap from './components/editors/Sap';
import SendMail from './components/editors/SendMail';
import Sql from './components/editors/Sql';
import SqlPlus from './components/editors/SqlPlus';
import TableRead from './components/editors/TableRead';
import WorkflowActionCreate from './components/editors/WorkflowActionCreate';
import WorkflowCreate from './components/editors/WorkflowCreate';
import Conditions from './components/editors/Conditions';

// Interfaces
import { UpdatedNode } from '../../../../../interfaces/nodeDefinitions';

// SCSS
import './NodeDefinitionEditor.scss';

import { v4 as uuidv4 } from 'uuid';

const INITIAL_UPDATED_NODE = {
  node_definition_id: '',
  description: '',
  config: {
    script: '',
    run_as_user: '',
    client: '',
    jobname: '',
    program_name: '',
    variant: '',
  },
  name: '',
  default_queue_name: '',
  queue_name: '',
  parameters: [],
  child_nodes: [],
  child_edges: [],
  handles: [],
  type: '',
  zone_id: 1,
};

const ModalDialog = ({ show, onChange }) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        onChange(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header>
      <Modal.Body>All changes have been saved!</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            onChange(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AlertDialog = ({ error, setError }) => {
  if (!error) return null;
  return (
    <>
      <Alert
        className="mt-4"
        onClose={() => setError(null)}
        variant="soft-danger"
        dismissible
      >
        <Alert.Heading>An error has occurred!</Alert.Heading>
        <p>{error}</p>
      </Alert>
    </>
  );
};

// Editors
const nodes = {
  bash: Bash,
  flow: Flow,
  sap: Sap,
  rest: Rest,
  sendMail: SendMail,
  sql: Sql,
  sqlplus: SqlPlus,
  tableRead: TableRead,
  workflowActionCreate: WorkflowActionCreate,
  workflowCreate: WorkflowCreate,
  conditions: Conditions,
};

// Determines which editor based on type
const Editor = ({ state, setState, onChange, onArrayChange }) => {
  const { type } = state || {};

  if (!type) return <div>No type found</div>;

  const ComponentName = nodes[type];

  return (
    <ComponentName
      state={state}
      setState={setState}
      onChange={onChange}
      onArrayChange={onArrayChange}
    />
  );
};

// Global handler
const handleCancel = (e) => {
  e.preventDefault();
  window.close();
};

// Main editor component
const NodeDefinitionEditor = () => {
  const { nodeId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updatedNode, setUpdatedNode] =
    useState<UpdatedNode>(INITIAL_UPDATED_NODE);

  const [showError, setShowError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const storeNodes = useSelector(
    (state: any) => state?.customerNodeDefinitions.nodes
  );
  const { name = '', description, config = {}, type } = updatedNode || {};

  // Copy the store to component state so we can edit at will
  const setInitialNode = (response) => {
    const { payload } = response;
    const mappedNodes = payload.child_nodes.map((node) => {
      const { id, label, handles } = node;
      return {
        id,
        data: { label, handles },
        ...node,
      };
    });
    setUpdatedNode({ ...payload, child_nodes: mappedNodes });
  };

  // Retrieve the current node's properties
  useEffect(() => {
    const fetchData = async () => {
      if (!nodeId) return;
      try {
        const response = await dispatch(
          fetchCustomerNodeDefinitionById(Number(nodeId))
        );
        if (response) {
          setInitialNode(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, nodeId]);

  const handleArrayChange = (e, index, arrayName = 'parameters') => {
    const { name, value, type, checked } = e.target;
    let newValue = value;

    if (type === 'checkbox') {
      newValue = checked;
    }

    if (index >= 0) {
      const copyItems = [...updatedNode[arrayName]];

      copyItems[index] = {
        ...copyItems[index],
        [name]: newValue,
      };

      return setUpdatedNode((prev) => ({
        ...prev,
        [arrayName]: [...copyItems],
      }));
    }
  };

  const handleChange = (e, isConfig = false) => {
    const { name, value } = e.target;

    // Nested config property
    if (isConfig) {
      return setUpdatedNode((prev) => ({
        ...prev,
        config: { ...config, [name]: value },
      }));
    }

    // All root properties
    return setUpdatedNode({ ...updatedNode, [name]: value });
  };

  // Save all local changes to server
  const handleSubmit = async (e) => {
    e.preventDefault();
    // clean up state, remove selectedNode, library from response before submitting
    try {
      const response: any = await dispatch(
        updateCustomerNodeDefinition(updatedNode)
      );

      if (response?.payload?.error) {
        return setShowError(response?.payload?.error);
      }
      return setShowModal(true);
    } catch (error) {
      console.error('Failed to update node definition:', error);
    }
  };

  const handleTabSelect = async (tab) => {
    if (tab === 'audit') {
      try {
        const response: any = await dispatch(
          fetchCustomerNodeDefinitionAuditTrail(Number(nodeId))
        );
      } catch (error) {
        console.error('Failed to fetch audit trail:', error);
      }
    }
  };

  return (
    <Container fluid>
      <ModalDialog show={showModal} onChange={setShowModal} />
      <AlertDialog error={showError} setError={setShowError} />
      <Form>
        <Row className="pt-4 pb-4 justify-content-lg-center sticky-top">
          <Col lg="12">
            <h2>
              Edit node {nodeId} - {name}
            </h2>
          </Col>
        </Row>
        <Row className="mb-4 justify-content-lg-center">
          <Col lg="12">
            <Tabs
              defaultActiveKey="definition"
              id="bash-tabs"
              className="mb-2 sticky-tabs border-bottom"
              variant="underline"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="definition" title="Definition">
                <Form.Group className="mb-2 w-25" controlId="form-NodeName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={name || ''}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
                <Editor
                  state={updatedNode}
                  setState={setUpdatedNode}
                  onChange={handleChange}
                  onArrayChange={handleArrayChange}
                />
              </Tab>
              <Tab eventKey="parameters" title="Parameters">
                <Form.Group className="mb-2 w-75" controlId="form-Parameters">
                  <Form.Label className="mb-2">Current Parameters</Form.Label>
                  <Parameters
                    state={updatedNode}
                    setState={setUpdatedNode}
                    onChange={handleArrayChange}
                  />
                </Form.Group>
              </Tab>

              <Tab eventKey="properties" title="Properties">
                <Form.Group className="mb-2 w-50" controlId="form-Properties">
                  <Form.Label className="mb-2">Current Properties</Form.Label>
                  <Properties
                    state={updatedNode}
                    setState={setUpdatedNode}
                    onChange={handleChange}
                    onArrayChange={handleArrayChange}
                  />
                </Form.Group>
              </Tab>

              <Tab eventKey="audit" title="Audit trail">
                <AuditTrail nodeId={nodeId} />
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <Row className="pt-4 pb-4 justify-content-lg-center border-top sticky-bottom">
          <Col lg="12">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="ms-2"
              onClick={handleSubmit}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NodeDefinitionEditor;
