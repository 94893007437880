import { useState, useLayoutEffect } from 'react';
import ExpandableRowContent from './ExpandableRowContent';

interface Instance {
  node_instance_id: number;
  definition_id: number;
  name: string;
  status: string;
  created_at: string;
  scheduled_start_datetime: string;
  scheduled_start_timezone: string;
  queue_name: string;
  sent_to_worker_name: string;
  schedule: any;
}

interface ExpandableTableRowProps {
  instance: Instance;
  depth?: number;
}

const ExpandableTableRow = ({ instance, depth = 0 }: ExpandableTableRowProps) => {
  const [expanded, setExpanded] = useState(false);
  // Check if the instance name includes "flow" (case insensitive)
  const isExpandable = instance.name.toLowerCase().includes('flow');
  const [colWidths, setColWidths] = useState([]);

  useLayoutEffect(() => {
    const syncHeaderWidths = () => {
      const headers = document.querySelectorAll("thead th");
      const widths = Array.from(headers).map((th) => th.getBoundingClientRect().width);
      setColWidths(widths);
    };
  
    syncHeaderWidths();
  
    window.addEventListener("resize", syncHeaderWidths);
  
    return () => {
      window.removeEventListener("resize", syncHeaderWidths);
    };
  }, []);
  
  const toggleExpand = () => {
    if (isExpandable) {
      setExpanded((prev) => !prev);
    }
  };

  return (
    <>
      <tr
        onClick={toggleExpand}
        style={{ cursor: isExpandable ? 'pointer' : 'default'}}
      >
        <td style={{paddingLeft:`${depth*5 + 10}px`, width: `${colWidths[0]}px` }} >{instance.node_instance_id}</td>
        <td style={{ width: `${colWidths[1]}px` }}>{instance.definition_id}</td>
        <td style={{ width: `${colWidths[2]}px` }}>{instance.name}</td>
        <td style={{ width: `${colWidths[3]}px` }}>{instance.status}</td>
        <td style={{ width: `${colWidths[4]}px` }}>{new Date(instance.created_at).toLocaleDateString()}</td>
        <td style={{ width: `${colWidths[5]}px` }}>{instance.scheduled_start_datetime}</td>
        <td style={{ width: `${colWidths[6]}px` }}>{instance.scheduled_start_timezone}</td>
        <td style={{ width: `${colWidths[7]}px` }}>
          {instance.scheduled_start_datetime
            ? new Date(instance.scheduled_start_datetime).toLocaleString(undefined, {
                timeZoneName: 'short',
              })
            : ''}
        </td>
        <td style={{ width: `${colWidths[8]}px` }}> {instance?.schedule ? `Every ${instance?.schedule?.interval} ${instance?.schedule?.unit}` : ''}</td>
        <td style={{ width: `${colWidths[9]}px` }}>{instance.queue_name}</td>
        <td style={{ width: `${colWidths[10]}px` }}>{instance.sent_to_worker_name}</td>
        <td style={{ width: `${colWidths[11]}px` }}></td>
      </tr>
      {expanded && isExpandable && (
        <tr>
          <td colSpan={12}>
            {/* Render nested expandable content */}
            <ExpandableRowContent instanceId={instance.node_instance_id}depth={depth + 1}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default ExpandableTableRow;