import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConnections } from '../../../../../../../redux/slices/lists/connectionsSlice';

const Rest = ({ state, onChange }) => {
  const { body = '', path = '', method = '' } = state.config || {};

  const methodList = ['GET', 'POST', 'PUT', 'DELETE'];

  const dispatch = useDispatch();
  const { connections = [] } = useSelector(
    (state: any) => state?.listOfConnections
  );

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        await dispatch(getConnections('rest'));
      } catch (error) {
        console.error('Error fetching connections:', error);
      }
    };
    fetchConnections();
  }, []);

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-method">
        <Form.Label>Method</Form.Label>
        <Form.Select
          aria-label="method-select"
          name="method"
          value={method}
          onChange={(e) => onChange(e, true)}
        >
          <option>Select method</option>
          {methodList.map((method, index) => {
            return <option key={index} value={method}>{method}</option>;
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-connection">
        <Form.Label>Connection</Form.Label>
        <Form.Select
          aria-label="connection-select"
          name="connection"
          onChange={(e) => onChange(e, true)}
        >
          <option>Select connection</option>
          {connections &&
            connections.map((connection, index) => {
              const { connection_id, connection_name } = connection;

              return (
                <option key={index} value={connection_id}>
                  {connection_name}
                </option>
              );
            })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-path">
        <Form.Label>Path</Form.Label>
        <Form.Control
          type="text"
          name="path"
          value={path}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-body">
        <Form.Label>Body</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          name="body"
          value={body}
          onChange={(e) => onChange(e, true)}
          aria-label="With textarea"
        />
      </Form.Group>
    </>
  );
};

export default Rest;
