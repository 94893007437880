import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Tab, Tabs, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportDefinitionById, ReportDefinition, updateReportDefinition } from '../../../../redux/slices/reportsSlice';
import { AppDispatch, RootState } from '../../../../redux/store';

interface EditReportDefinitionProps {
    show: boolean;
    onClose: () => void;
    selectedReportId: number | null;
    onSave: (updatedReport: ReportDefinition) => void;
}

const EditReportDefinition: React.FC<EditReportDefinitionProps> = ({ show, onClose, selectedReportId, onSave }) => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedReport = useSelector((state: RootState) => state.reports.selectedReport);
    const [error, setError] = useState<string | null>(null);

    // Local state for editable fields
    const [formData, setFormData] = useState<Partial<ReportDefinition>>({
        name: '',
        query: '',
    });

    useEffect(() => {
        if (selectedReportId) {
            dispatch(fetchReportDefinitionById(selectedReportId))
                .then((response: any) => {
                    if (response.payload) {
                        setFormData({
                            name: response.payload.name,
                            query: response.payload.query,
                        });
                    }
                })
                .catch((error) => {
                    setError('Failed to fetch report definition');
                    console.error('Error fetching report definition:', error);
                });
        }
    }, [dispatch, selectedReportId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        if (selectedReport && formData.name && formData.query) {
            try {
                const updatedReport = {
                    ...selectedReport,
                    ...formData,
                };
                await dispatch(updateReportDefinition(updatedReport));
                onSave(updatedReport);
                onClose();
            } catch (error) {
                setError('Failed to save report definition');
                console.error('Error updating report:', error);
            }
        }
    };

    return (
        <Modal show={show} onHide={onClose} backdrop="static" size="xl" fullscreen="xl-down">

            <Modal.Header closeButton>
                <Modal.Title>Edit Report Definition</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <p className="text-danger">{error}</p>}
                <Tabs defaultActiveKey="details" id="edit-report-tabs" className="mb-3" variant="underline">
                    <Tab eventKey="details" title="Details">
                        {selectedReport ? (
                            <Form>
                                <Form.Group controlId="name" className="mt-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder="Enter report name"
                                    />
                                </Form.Group>

                                <Form.Group controlId="query" className="mt-3">
                                    <Form.Label>Query</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        name="query"
                                        value={formData.query}
                                        onChange={handleInputChange}
                                        placeholder="Enter SQL query"
                                    />
                                </Form.Group>
                            </Form>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Tab>
                    <Tab eventKey="audit" title="Audit Trail">
                        {selectedReport?.audit_trail ? (
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                    <th>Timestamp</th>
                                        <th>Type</th>
                                        <th>User</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedReport.audit_trail.map((audit) => (
                                        <tr key={audit.audit_trail_id}>
                                            <td>{new Date(audit.created_at).toLocaleString()}</td>
                                            <td>{audit.audit_details.action}</td>
                                            <td>{audit.user_name}</td>
                                            <td>
                                                {audit.audit_details.differences ? (
                                                    <div>
                                                        {audit.audit_details.differences.map((diff: any, index: number) => (
                                                            <div key={index} style={{ marginBottom: '10px' }}>
                                                                <strong>Property changed: {diff.field}</strong>
                                                                <div>
                                                                    <strong>Old Value:</strong>{' '}
                                                                    <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                                                        {JSON.stringify(diff.old_value, null, 2)}
                                                                    </pre>
                                                                </div>
                                                                <div>
                                                                    <strong>New Value:</strong>{' '}
                                                                    <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                                                        {JSON.stringify(diff.new_value, null, 2)}
                                                                    </pre>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    'No differences available'
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>No audit trail available</p>
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditReportDefinition;
