import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/common/SearchBox';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import EditReportDefinition from './EditReportDefinition'; // New component for editing reports
import useAdvanceTable from 'hooks/useAdvanceTable';
import {
  fetchReportDefinitions,
  fetchReportDefinitionById,
  updateReportDefinition,
} from '../../../../redux/slices/reportsSlice';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate } from 'react-router-dom';
import { ReportDefinition } from '../../../../redux/slices/reportsSlice';
import { useAppDispatch } from '../../../../redux/store'; // Adjust the path as needed
import PaginationComponent from '../../../../components/nors/PaginationComponent';

const ListReportDefinitions = () => {
  const { t } = useTranslation();
//   const dispatch = useDispatch();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Get data and pagination from the Redux state
  const reportDefinitions = useSelector((state: any) => state.reports.reportDefinitions);
  const pagination = useSelector((state: any) => state.reports.pagination);
  const loading = useSelector((state: any) => state.reports.status === 'loading');
  const error = useSelector((state: any) => state.reports.error);

  const { currentPage, totalPages, totalItems, pageSize } = pagination;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState<number | null>(null);
  const [limit, setLimit] = useState<number>(pagination.limit || 50);



  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setSearchTerm(value.length > 3 ? value : '');
      }, 300)
    );
  };

  const handlePageChange = (page: number) => {
    dispatch(
      fetchReportDefinitions({
        page,
        limit: limit,
        search: searchTerm,
        sortBy: 'rd_id',
        sortOrder: 'asc',
      })
    );
  };

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
    // dispatch(
    //     fetchReportDefinitions({
    //       page: 1, // Reset to the first page
    //       limit,
    //       search: searchTerm,
    //       sortBy: 'rd_id',
    //       sortOrder: 'asc',
    //     })
    //   );
  };

  useEffect(() => {
    dispatch(
      fetchReportDefinitions({
        page: currentPage,
        limit: limit,
        search: searchTerm.length > 3 ? searchTerm : undefined,
        sortBy: 'rd_id',
        sortOrder: 'asc',
      })
    );
  }, [dispatch, limit, searchTerm]);


  const handleAction = (action: string, rowData: ReportDefinition) => {
    if (action === 'edit') {
      // Set the selected report ID and open the modal
      setSelectedReportId(rowData.rd_id);
      setEditModalVisible(true);
    } else if (action === 'run') {
      // Navigate to the report run page
      navigate(`/reports/run/${rowData.rd_id}`);
    }
  };
  
  

  const handleSave = (updatedData: ReportDefinition) => {
    dispatch(updateReportDefinition(updatedData));
    setEditModalVisible(false);
  };

  const columns: ColumnDef<ReportDefinition>[] = [
    {
      accessorKey: 'rd_id',
      header: 'ID',
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      accessorKey: 'created_at',
      header: t('created-at'),
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString(),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const rowData = row.original;
        const actions = [
          { label: 'Edit', action: 'edit' },
          { label: 'Run', action: 'run' },
        ];

        return (
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary" size="sm">
              <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((actionItem, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleAction(actionItem.action, rowData)}
                >
                  {actionItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data: reportDefinitions,
    columns,
    pageSize: 100000,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (
    <Container fluid className="p-0">
      <EditReportDefinition
  show={editModalVisible}
  onClose={() => setEditModalVisible(false)}
  selectedReportId={selectedReportId} // Pass the selected report ID
  onSave={handleSave}
/>


      <Row className="m-0">
        <Col className="p-0">
          {error && <Alert variant="danger">{t('error-generic')}</Alert>}
          <div className="d-flex gap-2 mb-3">
            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto"
            />
            
          </div>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                hover: true,
                className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter
              navBtn
              currentPage={pagination.current_page} // Pass Redux state
              totalPages={pagination.total_pages} // Pass Redux state
              totalItems={pagination.total_records}
              pageSize={pagination.limit}
            />
            <PaginationComponent
                totalPages={pagination.total_pages}
                currentPage={pagination.current_page}
                onPageChange={handlePageChange}
                limit={pagination.limit}
                onLimitChange={handleLimitChange} // Update limit dynamically
              />
          </AdvanceTableProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default ListReportDefinitions;
