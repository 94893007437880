import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import api from 'services/api';
import PaginationComponent from '../../../../components/nors/PaginationComponent';

interface ReportRunnerResponse {
  rows: any[];
  pagination: {
    total_records: number;
    total_pages: number;
    current_page: number;
    limit: number;
    offset: number;
  };
}

const ReportRunner: React.FC = () => {
  const { rd_id } = useParams<{ rd_id: string }>();
  const [data, setData] = useState<ReportRunnerResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(50); // Limit state

  const fetchData = async (page: number = 1, newLimit: number = limit) => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get(`/report_definitions/${rd_id}/run`, {
        params: { limit: newLimit, offset: (page - 1) * newLimit },
      });
      setData(response.data);
    } catch (err) {
      setError('Failed to fetch report data');
      console.error('Error fetching report data:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rd_id) {
      fetchData();
    }
  }, [rd_id, limit]); // Re-fetch data when limit changes

  const handlePageChange = (page: number) => {
    fetchData(page, limit);
  };

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
    fetchData(1, newLimit); // Fetch data for the first page with the new limit
  };

  const columns = data?.rows.length
  ? Object.keys(data.rows[0]).map((key) => ({
      accessorKey: key,
      header: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
      cell: ({ row }: { row: any }) => {
        const value = row.original[key]; // Access the original row data using the key
        if (typeof value === 'object' && value !== null) {
          return (
            <pre style={{ whiteSpace: 'pre-wrap', maxWidth: '400px', overflow: 'auto' }}>
              {JSON.stringify(value, null, 2)}
            </pre>
          );
        }
        return value !== null && value !== undefined ? value.toString() : '-';
      },
    }))
  : [];



  const table = useAdvanceTable({
    data: data?.rows || [],
    columns,
    pageSize: 100000,
    pagination: true,
    sortable: false,
    selection: false,
  });

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <h3>Report Runner</h3>
          <p>Report ID: {rd_id}</p>
        </Col>
      </Row>
      {loading && <p>Loading...</p>}
      {error && <Alert variant="danger">{error}</Alert>}
      {data && (
        <Row>
          <Col>
            <AdvanceTableProvider {...table}>
              <AdvanceTable
                tableProps={{
                  size: 'sm',
                  hover: true,
                  className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
                }}
                rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
              />
              <AdvanceTableFooter
                currentPage={data.pagination.current_page}
                totalPages={data.pagination.total_pages}
                totalItems={data.pagination.total_records}
                pageSize={limit}
              />
              <PaginationComponent
                totalPages={data.pagination.total_pages}
                currentPage={data.pagination.current_page}
                onPageChange={handlePageChange}
                limit={limit}
                onLimitChange={handleLimitChange} // Update limit dynamically
              />
            </AdvanceTableProvider>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ReportRunner;
