import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';
import { UpdatedNode, NewNode } from '../../interfaces/nodeDefinitions.js';


// Define the structure of customer node definitions
export interface CustomerNodeDefinition {
    node_definition_id: number;
    type: string;
    name: string;
    config: {
        fields: {
            name: string;
            Value: any;
            editable: boolean;
        }[];
    };
    created_at?: string;
    updated_at?: string;
    node_type_fields?: NodeTypeField[]; // Added for `node_type_fields`
    audit_trail?: object;
    object_id?: number;
}

export interface NodeTypeField {
    name: string;
    type: string;
    Default?: any; // Optional field for default values
    JSONPath?: string; // Optional JSONPath for fetching dropdown options
    ValuesApi?: string; // Optional API endpoint for fetching dropdown values
}


interface PaginationState {
    totalItems: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
}

interface CustomerNodeDefinitionState {
    nodes: CustomerNodeDefinition[]; // The actual data
    pagination: PaginationState; // Pagination-related information
    status: string | null; // Loading or error state
    search: string; // Search term
    sortBy: string; // Field to sort by
    sortOrder: 'asc' | 'desc'; // Sorting direction
    error: string | null; // Error message
}

const initialState: CustomerNodeDefinitionState = {
    nodes: [],
    pagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 100,
    },
    status: null,
    search: '',
    sortBy: 'node_definition_id', // Default sort field
    sortOrder: 'asc', // Default sort order
    error: null,
};

export interface FetchParams {
    page: number;
    limit: number;
    type: string;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    sortColumn?: string;
    search?: string;
}

// Fetch customer node definitions with pagination, sorting, and search
export const fetchCustomerNodeDefinitions = createAsyncThunk(
    'customerNodeDefinitions/fetchCustomerNodeDefinitions',
    async (params: FetchParams) => {
        const { page, limit, type, sortBy, sortOrder, search = '', sortColumn = '' } = params;
        const response = await api.get(
            `/node_definitions?page=${page}&type=${type}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}${sortColumn && `&sortColumn=${sortColumn}`}&search=${search}`
        );
        return response.data;
    }
);

export const fetchCustomerNodeDefinitionById = createAsyncThunk<
    CustomerNodeDefinition, // The type of the payload the thunk resolves to
    number,                 // The type of the argument the thunk accepts
    { rejectValue: string } // Optional type for rejected value
>(
    'customerNodeDefinitions/fetchCustomerNodeDefinitionById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/node_definitions/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to fetch customer node definition');
        }
    }
);


// Create a new customer node definition
export const createCustomerNodeDefinition = createAsyncThunk(
    'customerNodeDefinitions/createCustomerNodeDefinition',
    async (definition: Omit<CustomerNodeDefinition | NewNode, 'node_definition_id'>) => {
        try {
            const response = await api.post('/node_definitions', definition);
            return response.data;
        }
        catch(error) {
            return error?.response?.data;
        }
    }
);

// Update an existing customer node definition
export const updateCustomerNodeDefinition = createAsyncThunk(
    'customerNodeDefinitions/updateCustomerNodeDefinition',
    async (definition: CustomerNodeDefinition | UpdatedNode) => {
        try {
            const response = await api.put(`/node_definitions/${definition.node_definition_id}`, definition);
            return response.data;
        } catch(error) {
            return error?.response?.data;
        }
    }
);

// Delete a single customer node definition by ID
export const deleteCustomerNodeDefinition = createAsyncThunk(
    'customerNodeDefinitions/deleteCustomerNodeDefinition',
    async (node_definition_id: number) => {
        await api.delete(`/node_definitions/${node_definition_id}`);
        return node_definition_id;
    }
);

// Delete multiple customer node definitions by IDs
export const deleteCustomerNodeDefinitions = createAsyncThunk(
    'customerNodeDefinitions/deleteCustomerNodeDefinitions',
    async (customerNodeDefinitionIds: number[]) => {
        await api.delete('/node_definitions', { data: { ids: customerNodeDefinitionIds } });
        return customerNodeDefinitionIds;
    }
);


export const fetchCustomerNodeDefinitionAuditTrail = createAsyncThunk<CustomerNodeDefinition, number>(
    'customerNodeDefinitions/fetchCustomerNodeDefinitionAuditTrail',
    async (id) => {
        try {
            const response = await api.get(`/node_definitions/audit_trail/${id}`);
            return response.data;
        } catch (error) {
            return error;
        }
    }
);

const customerNodeDefinitionsSlice = createSlice({
    name: 'customerNodeDefinitions',
    initialState,
    reducers: {
        setSortOrder: (state, action) => {
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerNodeDefinitions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCustomerNodeDefinitions.fulfilled, (state, action) => {
                const { pagination, nodes } = action.payload;
                state.nodes = nodes;
                state.pagination.totalItems = pagination.total_records;
                state.pagination.totalPages = pagination.total_pages;
                state.pagination.pageSize = pagination.limit;

                if (pagination.current_page > pagination.total_pages) {
                    state.pagination.currentPage = 1;
                } else {
                    state.pagination.currentPage = pagination.current_page;
                }
            })
            .addCase(fetchCustomerNodeDefinitions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
            .addCase(fetchCustomerNodeDefinitionById.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCustomerNodeDefinitionById.fulfilled, (state, action) => {
                const updatedItem = action.payload;
                if (updatedItem.node_type_fields) {
                    const combinedFields = updatedItem.node_type_fields.map((field) => ({
                        ...field,
                        ...updatedItem.config.fields.find((f) => f.name === field.name), // Merge matching config fields
                    }));

                    updatedItem.config.fields = combinedFields;
                }
                const index = state.nodes.findIndex(
                    (definition) => definition.node_definition_id === updatedItem.node_definition_id
                );
                if (index !== -1) {
                    state.nodes[index] = updatedItem;
                } else {
                    state.nodes.push(updatedItem);
                }
                state.status = null;
            })
            .addCase(fetchCustomerNodeDefinitionById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch customer node definition';
            })
            .addCase(createCustomerNodeDefinition.fulfilled, (state, action) => {
                state.nodes.push(action.payload);
            })
            // Deep merge fields during an update
            .addCase(updateCustomerNodeDefinition.fulfilled, (state, action) => {
                const index = state.nodes.findIndex(
                    (definition) => definition.node_definition_id === action.payload.node_definition_id
                );

                if (index !== -1 && action?.payload?.config?.fields) {
                    state.nodes[index].config.fields = action.payload.config.fields; // Replace or deeply merge as needed
                }
            })
            .addCase(fetchCustomerNodeDefinitionAuditTrail.fulfilled, (state, action) => {
                const index = state.nodes.findIndex(
                    (definition) => definition.node_definition_id === Number(action.payload.object_id));
                if (index !== -1 && action?.payload?.audit_trail) {
                    state.nodes[index].audit_trail = action.payload.audit_trail; // Replace or deeply merge as needed
                }
            })
            .addCase(deleteCustomerNodeDefinition.fulfilled, (state, action) => {
                state.nodes = state.nodes.filter(
                    (definition) => definition.node_definition_id !== action.payload
                );
            })
            .addCase(deleteCustomerNodeDefinitions.fulfilled, (state, action) => {
                state.nodes = state.nodes.filter(
                    (definition) => !action.payload.includes(definition.node_definition_id)
                );
            });
    },
});

export const { setSortOrder, setSearch } = customerNodeDefinitionsSlice.actions;

export default customerNodeDefinitionsSlice.reducer;