import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchConnections,
  createConnection,
  updateConnection,
  deleteConnection,
} from '../../../../redux/slices/connectionsSlice';
import { fetchWorkers } from '../../../../redux/slices/workersSlice';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger,
} from 'components/base/RevealDropdown';
import ActionDropdownConnections from '../../../../components/common/ActionDropdownConnections';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import { Connection, Worker } from '../../../../redux/slices/connectionsSlice';
import api from 'services/api';

// Import form components
import ConnectionRest from './connection_rest';
import ConnectionDatabase from './connection_database';
import ConnectionSap from './connection_sap';
import ConnectionSQLPlus from './connection_sqlplus';

const ListConnections = () => {
  const dispatch = useDispatch();
  const connections = useSelector((state: any) => state.connections.items);
  const workers = useSelector((state: any) => state.workers.items); // Fetch workers from Redux

  const [showModal, setShowModal] = useState(false);
  const [currentConnection, setCurrentConnection] = useState<Connection | null>(
    null
  );

  const [connectionData, setConnectionData] = useState<Connection>({
    connection_id: 0,
    connection_name: '',
    connection_type: '',
    connection_parameters: {},
    workers: [],
  });

  const [databaseTypes, setDatabaseTypes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchConnections());
    dispatch(fetchWorkers()); // Fetch workers when loading connections
    fetchDatabaseTypes();
  }, [dispatch]);

  const fetchDatabaseTypes = async () => {
    try {
      const response = await api.get('/list_of_values/key/database_type');
      setDatabaseTypes(response.data);
    } catch (error) {
      console.error('Failed to fetch database types', error);
    }
  };

  const handleAction = async (connection_id: number, action: string) => {
    const connection = connections.find(
      (connection: Connection) => connection.connection_id === connection_id
    );
    if (action === 'remove') {
      dispatch(deleteConnection(connection_id));
    } else if (action === 'edit') {
      setCurrentConnection(connection);
      setConnectionData(
        connection
          ? { ...connection }
          : {
              connection_id: 0,
              connection_name: '',
              connection_type: '',
              connection_parameters: {},
              workers: [],
            }
      );
      setShowModal(true);
    }
  };

  const handleSave = () => {
    if (currentConnection) {
      dispatch(updateConnection({ ...currentConnection, ...connectionData }));
    } else {
      dispatch(createConnection(connectionData));
    }
    handleCloseModal();
  };

  const handleCloseModal = () => setShowModal(false);

  const handleParameterChange = (key: string, value: any) => {
    setConnectionData({
      ...connectionData,
      connection_parameters: {
        ...connectionData.connection_parameters,
        [key]: value,
      },
    });
  };

  const handleWorkerSelection = (worker: Worker, checked: boolean) => {
    const updatedWorkers = checked
      ? [
          ...connectionData.workers,
          {
            worker_id: worker.worker_id,
            worker_name: worker.worker_name,
            priority: 0,
          },
        ]
      : connectionData.workers.filter((w) => w.worker_id !== worker.worker_id);
    setConnectionData({ ...connectionData, workers: updatedWorkers });
  };

  const handleWorkerPriorityChange = (worker_id: number, priority: number) => {
    const updatedWorkers = connectionData.workers.map((w) =>
      w.worker_id === worker_id ? { ...w, priority } : w
    );
    setConnectionData({ ...connectionData, workers: updatedWorkers });
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleNewConnectionClick = () => {
    setCurrentConnection(null);
    setConnectionData({
      connection_id: 0,
      connection_name: '',
      connection_type: '',
      connection_parameters: {},
      workers: [],
    });
    setShowModal(true);
  };

  const columns: ColumnDef<Connection>[] = [
    {
      accessorKey: 'connection_name',
      header: 'Connection Name',
      cell: ({ row }) => (
        <span
          onClick={() => handleAction(row.original.connection_id, 'view')}
          style={{ cursor: 'pointer', color: '#007bff' }}
        >
          {row.original.connection_name}
        </span>
      ),
    },
    {
      id: 'action',
      cell: ({ row }) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownConnections
              connection_id={row.original.connection_id}
              onAction={handleAction}
            />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' },
      },
    },
  ];

  const table = useAdvanceTable({
    data: connections,
    columns,
    pageSize: 20,
    pagination: true,
    selection: true,
    sortable: true,
  });

  const renderFormFields = () => {
    switch (connectionData.connection_type) {
      case 'REST':
        return (
          <ConnectionRest
            connectionData={connectionData}
            handleParameterChange={handleParameterChange}
          />
        );
      case 'database':
        return (
          <ConnectionDatabase
            connectionData={connectionData}
            handleParameterChange={handleParameterChange}
          />
        );
      case 'sap':
        return (
          <ConnectionSap
            connectionData={connectionData}
            handleParameterChange={handleParameterChange}
          />
        );
        case 'sqlplus':
            return (
                <ConnectionSQLPlus
                connectionData={connectionData}
                handleParameterChange={handleParameterChange}
                />
            );
      default:
        return null;
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0 mb-4">
        <Col className="p-0 d-flex justify-content-between align-items-center">
          <SearchBox
            placeholder="Search..."
            size="sm"
            onChange={handleSearchInputChange}
            className="mx-auto"
          />
          <Button
            variant="primary"
            size="sm"
            onClick={handleNewConnectionClick}
          >
            New Connection
          </Button>
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0">
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                className:
                  'phoenix-table fs-9 mb-0 border-top border-translucent w-100',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            {/* <AdvanceTableFooter navBtn /> */}
          </AdvanceTableProvider>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {currentConnection ? 'Edit Connection' : 'New Connection'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="connectionName">
              <Form.Label>Connection Name</Form.Label>
              <Form.Control
                type="text"
                value={connectionData.connection_name}
                onChange={(e) =>
                  setConnectionData({
                    ...connectionData,
                    connection_name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="connectionType">
              <Form.Label>Connection Type</Form.Label>
              <Form.Control
                as="select"
                value={connectionData.connection_type}
                onChange={(e) =>
                  setConnectionData({
                    ...connectionData,
                    connection_type: e.target.value,
                  })
                }
              >
                <option value="">Select Connection Type</option>
                <option value="REST">REST</option>
                <option value="database">Database</option>
                <option value="sap">SAP</option>
                <option value="sqlplus">SQLPlus</option>
              </Form.Control>
            </Form.Group>

            {/* Dynamically render form fields based on connection type */}
            {renderFormFields()}

            {/* Worker Selection and Priority */}
            <Form.Group controlId="workers">
              <Form.Label>Workers</Form.Label>
              {workers.map((worker: Worker) => (
                <div key={worker.worker_id} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label={worker.worker_name}
                    checked={connectionData.workers.some(
                      (w) => w.worker_name === worker.worker_name
                    )}
                    onChange={(e) =>
                      handleWorkerSelection(worker, e.target.checked)
                    }
                  />
                  {connectionData.workers.some(
                    (w) => w.worker_name === worker.worker_name
                  ) && (
                    <Form.Control
                      type="number"
                      value={
                        connectionData.workers.find(
                          (w) => w.worker_id === worker.worker_id
                        )?.priority || 0
                      }
                      onChange={(e) =>
                        handleWorkerPriorityChange(
                          worker.worker_id,
                          parseInt(e.target.value, 10)
                        )
                      }
                      placeholder="Priority"
                    />
                  )}
                </div>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ListConnections;
