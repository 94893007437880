import { ChangeEvent, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  Tabs,
  Tab,
  CloseButton,
} from 'react-bootstrap';

// ReactFlow
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  useReactFlow,
} from 'reactflow';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import SearchBox from 'components/common/SearchBox';

import StandardNode from './node_types/StandardNode';
import LoopbackEdge from './edge_types/LoopbackEdge';

const nodeTypes = {
  default: StandardNode,
  bash: StandardNode,
  flow: StandardNode,
  sap: StandardNode,
  rest: StandardNode,
  tableRead: StandardNode,
  sendMail: StandardNode,
  sql: StandardNode,
  sqlplus: StandardNode,
  workflowActionCreate: StandardNode,
  workflow: StandardNode,
  workflowCreate: StandardNode,
};

const edgeTypes = {
  loopback: LoopbackEdge,
};

import {
  fetchCustomerNodeDefinitions,
  fetchCustomerNodeDefinitionById,
} from '../../../../../../../redux/slices/customer_node_definitionsSlice';

import Queues from '../ui/Queues';

const RenderLibraryNodes = ({ state, onDragStart, onSearch }) => {
  const { t } = useTranslation();
  const { nodes = [] } = useSelector(
    (state: any) => state?.customerNodeDefinitions
  );
  return (
    <>
      <SearchBox
        placeholder={t('search') + '...'}
        size="sm"
        onChange={onSearch}
        className="mb-4"
        style={{ width: '100%' }}
      />
      {nodes.map((node, index) => {
        const { name, type } = node;

        return (
          <div
            onDragStart={(event) => onDragStart(event, node)}
            data-type={type}
            data-name={name}
            key="index"
            className="library-node"
            draggable
          >
            <span className="text">{name}</span>
            <span className="type">{type}</span>
          </div>
        );
      })}
    </>
  );
};

const RenderMiniEditor = ({ state, onChange, onQueueChange, onClose }) => {
  const { selectedNode = {}, child_nodes } = state;
  const { parameters = [], default_queue_name, queue_name, id } = selectedNode;
  const currentChildNode = child_nodes.find((node) => node.id === id);

  const selectedQueue = currentChildNode?.queue_name;

  let listOfParameters = parameters;

  if (currentChildNode?.parameters) {
    listOfParameters = parameters.map((currentParam) => {
      const matchingParam = currentChildNode.parameters.find(
        (param) => param.name === currentParam.name
      );
      if (matchingParam) {
        return { ...currentParam, value: matchingParam.value };
      }
      return currentParam;
    });
  }

  return (
    <>
      <Form.Group>
        <Form.Label>Editing: {id} </Form.Label>
      </Form.Group>
      {listOfParameters &&
        listOfParameters.map((parameter, index) => {
          const { name, value, default_expression } = parameter;

          return (
            <Form.Group
              key={`${name}-${index}-${id}`}
              className="mb-2"
              controlId={`${name}-${index}`}
            >
              <Form.Label>{name}</Form.Label>
              <Form.Control
                type="text"
                name={name}
                value={value}
                placeholder={!value && default_expression}
                onChange={(e) => onChange(e, name, id)}
              />
            </Form.Group>
          );
        })}

      <Queues
        selectedValue={selectedQueue}
        defaultValue={default_queue_name}
        onChange={(e) => onQueueChange(e, id)}
      />

      <Button onClick={onClose} className="mt-4" variant="secondary">
        Close
      </Button>
    </>
  );
};

const RenderMiniReadOnlySidebar = ({ state, onClose }) => {
  const { selectedNode = {}, child_nodes } = state;
  const {
    name,
    type,
    status,
    scheduled_start_datetime,
    scheduled_start_timezone,
    worker_name,
    output,
    files,
    exit_code,
    error,
    worker_pid,
    remote_id,
    activated_handles,
    parameters = [],
    default_queue_name,
    queue_name,
    id,
    node_instance_id,
    hasChildren,
  } = selectedNode;

  // Status -> additional a modal when you click on status which shows status_updates (array of objects with datetime, new_status and message)
  // Output (show one line, when click on it a modal with all details. Line breaks are \n, should render fine in a textarea)
  // Files (show list of property filename, on click a modal with the content, only clickable if filecontent != null)


  const date = new Date(scheduled_start_datetime);
  const localeDate = date.toLocaleString();

  const openChildNodes = () => {
    const url = `/instance_viewer/${id}`;
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <>
      <Form.Group>
        <Form.Label>
          Viewing sub node: {node_instance_id} - {name}{' '}
        </Form.Label>
      </Form.Group>
      {hasChildren && (
        <Button className="mb-3" onClick={openChildNodes}>
          Show child nodes (opens in new tab) {hasChildren}{' '}
        </Button>
      )}
      <Form.Group>
        <Form.Label>Type</Form.Label>
        <Form.Control disabled type="text" name="Type" value={type} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Status</Form.Label>
        <Form.Control disabled type="text" name="Status" value={status} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Scheduled Start Datetime</Form.Label>
        <Form.Control
          disabled
          type="text"
          name="Scheduled Start Datetime"
          value={`${localeDate} (${scheduled_start_timezone})`}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Worker Name</Form.Label>
        <Form.Control
          disabled
          type="text"
          name="Worker Name"
          value={worker_name}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Output</Form.Label>
        <Form.Control
          disabled
          type="text"
          as="textarea"
          rows={3}
          name="Output"
          value={output}
        />
      </Form.Group>

      {files && (
        <Form.Group>
          <Form.Label>Files</Form.Label>

          <ul>
            {files.map((handle) => {
              const { filename, filecontent } = handle;
              return <li title={filecontent}>{filename}</li>;
            })}
          </ul>
        </Form.Group>
      )}

      {exit_code && (
        <Form.Group>
          <Form.Label>Exit Code</Form.Label>
          <Form.Control
            disabled
            type="text"
            name="Exit Code"
            value={exit_code}
          />
        </Form.Group>
      )}

      {error && (
        <Form.Group>
          <Form.Label>Error</Form.Label>
          <Form.Control disabled type="text" name="Error" value={error} />
        </Form.Group>
      )}

      {worker_pid && (
        <Form.Group>
          <Form.Label>PID</Form.Label>
          <Form.Control disabled type="text" name="PID" value={worker_pid} />
        </Form.Group>
      )}

      {remote_id && (
        <Form.Group>
          <Form.Label>Remote ID</Form.Label>
          <Form.Control
            disabled
            type="text"
            name="Remote id"
            value={remote_id}
          />
        </Form.Group>
      )}

      {activated_handles && (
        <Form.Group>
          <Form.Label>Activated Handles</Form.Label>
          <ul>
            {activated_handles.map((handle) => {
              const { label, id } = handle;
              return <li title={id}>{label}</li>;
            })}
          </ul>
        </Form.Group>
      )}
      <Button onClick={onClose} className="mt-4" variant="secondary">
        Close
      </Button>
    </>
  );
};

const Flow = ({
  state,
  onChange,
  setState,
  onNodeDoubleClickHandler = null,
  showNodeLibrary = true,
  disabled = false,
}) => {
  const { child_nodes = [], child_edges = [] } = state;
  const { fitView } = useReactFlow();

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const minimumSearchLength = 3;
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    if (!showNodeLibrary) return;
    const getNodeLibrary = async () => {
      try {
        await dispatch(
          fetchCustomerNodeDefinitions({
            limit: 15,
            type: 'All',
            sortBy: 'node_definition_id',
            search: searchTerm,
            sortOrder: 'asc',
            page: 1,
          })
        );
      } catch (error) {
        console.error('Error fetching node definitions:', error);
      }
    };
    getNodeLibrary();
  }, [searchTerm]);

  const styles = {
    width: '100%',
    height: 300,
  };

  // ReactFlow Handlers
  const onNodeDoubleClick = useCallback(
    async (e) => {
      const { child_nodes, child_edges } = state;
      const currentNodeId = e.target.getAttribute('data-id');
      const currentNode: any = reactFlowInstance.getNode(currentNodeId);
      const { node_definition_id } = currentNode;

      const getNodeDetails = async () => {
        try {
          const response: any = await dispatch(
            fetchCustomerNodeDefinitionById(node_definition_id)
          );
          if (response) {
            return response.payload;
          }
        } catch (error) {
          console.error('Error fetching node definitions:', error);
        }
      };
      const currentNodeDetails = await getNodeDetails();
      setState({
        ...state,
        selectedNode: { ...currentNodeDetails, id: currentNodeId },
      });
    },
    [state]
  );

  const onConnect = useCallback((params) => {
    return setState((prev) => {
      const cleanEdgeId = (edgeId) =>
        edgeId.startsWith('reactflow__edge-')
          ? edgeId.replace('reactflow__edge-', '')
          : edgeId;
      const id = cleanEdgeId(uuidv4()); // use UUID without `reactflow__edge-` prefix

      const sourceNode = reactFlowInstance.getNode(params.source);
      const targetNode = reactFlowInstance.getNode(params.target);

      const isTargetLeftOfSource =
        targetNode?.position.x < sourceNode?.position.x;
      const edgeType = isTargetLeftOfSource ? 'loopback' : 'default';
      const newEdge = {
        id,
        source: params.source,
        target: params.target,
        sourceHandle: params.sourceHandle, // Store the source handle ID
        targetHandle: params.targetHandle, // Store the target handle ID
        type: edgeType,
      };

      const addedEdges = addEdge(newEdge, prev.child_edges);

      return {
        ...prev,
        child_edges: addedEdges,
      };
    });
  }, []);

  const onNodesChange = useCallback((changes) => {
    return setState((prev) => {
      const changedNodes = applyNodeChanges(changes, prev.child_nodes);
      return {
        ...prev,
        child_nodes: changedNodes,
      };
    });
  }, []);

  const onEdgesChange = useCallback((changes) => {
    return setState((prev) => {
      const changedEdges = applyEdgeChanges(changes, prev.child_edges);
      return {
        ...prev,
        child_edges: changedEdges,
      };
    });
  }, []);

  const onDrop = (event) => {
    const id = 0;
    const { screenToFlowPosition } = reactFlowInstance;
    const { child_nodes } = state;
    const selectedNode = JSON.parse(
      event.dataTransfer.getData('application/reactflow')
    );
    const { type, node_definition_id, name, handles } = selectedNode;

    event.preventDefault();

    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    let max = child_nodes.reduce((acc, value) => {
      const idNum = parseInt(value.id.replace('node-', ''), 10);
      return idNum > acc ? idNum : acc;
    }, 0);
    // Generate a unique ID for the new node
    max += 1;

    const newNodeId = `node-${max}`;
    const newNode = {
      id: newNodeId,
      node_definition_id,
      position,
      name,
      type,
      data: { label: name, handles },
      label: name,
    };

    return setState((prev) => ({
      ...prev,
      child_nodes: child_nodes.concat(newNode),
    }));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDragStart = (event, nodeType) => {
    return event.dataTransfer.setData(
      'application/reactflow',
      JSON.stringify(nodeType)
    );
  };

  // Click handlers
  const handleChildNodeChange = (
    event: ChangeEvent<HTMLInputElement>,
    name,
    nodeId
  ) => {
    const { value } = event.target;
    const currentParameter = { name, value, nodeId };

    const mappedNodes = child_nodes.map((node) => {
      const { parameters = [] } = node;

      // Update parameter if it matches an id with any child_node
      if (node.id === nodeId) {
        const newParams = parameters
          ? parameters.map((parameter) => {
              const { name: currentName } = parameter;

              if (currentName === name) {
                return { name, value };
              }
              return parameter;
            })
          : [];

        // Check if the parameter was not found in child_nodes and add it
        const hasFoundParameter = newParams.some(
          (param) => param.name === name
        );
        if (!hasFoundParameter) {
          newParams.push({ name, value });
        }

        // Remove empty value items from array
        newParams.forEach((param, index) => {
          if (param.value === '') {
            newParams.splice(index, 1);
          }
        });

        return { ...node, parameters: newParams };
      }

      return node;
    });

    return setState((prev) => ({
      ...prev,
      child_nodes: mappedNodes,
    }));
  };

  const handleQueueChange = (event: ChangeEvent<HTMLInputElement>, nodeId) => {
    const { value } = event.target;

    const mappedNodes = child_nodes.map((node) => {
      if (node.id === nodeId) {
        return { ...node, queue_name: value || null };
      }
      return node;
    });

    return setState((prev) => ({
      ...prev,
      child_nodes: mappedNodes,
    }));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const searchThreshold = value.length >= minimumSearchLength ? value : '';

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setSearchTerm(searchThreshold);
      }, 300)
    );
  };

  const handleCloseEditor = () => {
    return setState({ ...state, selectedNode: null });
  };

  return (
    <>
      <Row className="react-flow-container">
        {showNodeLibrary && (
          <Col lg="2" className="node-library border-end">
            <Form.Group>
              <Form.Label>Node Library</Form.Label>
              <RenderLibraryNodes
                state={state}
                onDragStart={onDragStart}
                onSearch={handleSearchChange}
              />
            </Form.Group>
          </Col>
        )}
        <Col lg={state.selectedNode ? '8' : '10'}>
          <div className="react-flow-wrapper-container">
            {disabled ? null : <Form.Label>Flow edit</Form.Label>}
            <div
              id="reactflow-wrapper"
              style={{ height: '100%', width: '100%' }}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              <ReactFlow
                style={styles}
                nodes={child_nodes}
                edges={child_edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDoubleClick={
                  onNodeDoubleClickHandler || onNodeDoubleClick
                }
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                proOptions={{ hideAttribution: true }}
                fitView
              >
                <Background />
                <Controls position="top-left" />
              </ReactFlow>
            </div>
          </div>
        </Col>
        {state.selectedNode && (
          <Col lg={disabled ? '4' : '2'} className="mini-editor border-start">
            {disabled ? (
              <RenderMiniReadOnlySidebar
                state={state}
                onClose={handleCloseEditor}
              />
            ) : (
              <RenderMiniEditor
                state={state}
                onChange={handleChildNodeChange}
                onQueueChange={handleQueueChange}
                onClose={handleCloseEditor}
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Flow;
