import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

// Which properties are optional?
interface Connections {
  connections: Connection[];
  status: string | null;
  error: string | null;
}

interface Connection {
  connection_id: number;
  connection_name: string;
}

const initialState = {
  connections: [],
  status: null,
  error: null,
};

export const getConnections = createAsyncThunk<Connections, string>(
  'connections/getConnections',
  async (type, { rejectWithValue }) => {
    const response = await api.get(`/connections?connection_type=${type}`);
    return response.data;
  }
);

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchWorkers
      .addCase(getConnections.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        const { connections } = action.payload
        state.connections = connections;
        state.status = 'succeeded';
      })
      .addCase(getConnections.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch connections';
      });
  },
});

export default connectionsSlice.reducer;
