import { Form } from 'react-bootstrap';

const Sap = ({ state, onChange }) => {
  const { client, jobname, program_name, variant } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-client">
        <Form.Label>Client</Form.Label>
        <Form.Control
          type="text"
          name="client"
          value={client}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-jobname">
        <Form.Label>Job name</Form.Label>
        <Form.Control
          type="text"
          name="jobname"
          value={jobname}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-program_name">
        <Form.Label>Program name</Form.Label>
        <Form.Control
          type="text"
          name="program_name"
          value={program_name}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-variant">
        <Form.Label>Variant</Form.Label>
        <Form.Control
          type="text"
          name="variant"
          value={variant}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default Sap;