import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getQueues,  updateQueue,deleteQueue, createQueue, openQueue, closeQueue } from '../../../../redux/slices/lists/queuesSlice';
import Button from 'components/base/Button';
import { Modal, Dropdown, Form } from 'react-bootstrap';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { fetchWorkers } from '../../../../redux/slices/workersSlice';
import { Worker } from '../../../../redux/slices/connectionsSlice';
import {
    fetchConnections,
  } from '../../../../redux/slices/connectionsSlice';
import { getZones } from '../../../../redux/slices/lists/zonesSlice';
import '../zones/dropdownStyle.css'; 

const ListQueues = () => {
  const dispatch = useDispatch();
  const { queues, status } = useSelector((state: any) => state.queues);
  const { zones } = useSelector((state: any) => state.listOfZones);
  const connections = useSelector((state: any) => state.connections.items);
  const workers = useSelector((state: any) => state.workers.items); 
  const [deleteConfirmZone, setDeleteConfirmZone] = useState('');
  const [deleteConfirmQueue, setDeleteConfirmQueue] = useState('');
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);

  // Modal state
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newQueue, setNewQueue] = useState<any>({
    queue_name: '',
    queue_new_name: '',
    queue_status: 'open',
    zone_name: 'Root',
    zone_new_name:'',
    workers: [],
    connections: []
  });

  useEffect(() => {
    if (status === null) {
      dispatch(getQueues());
      dispatch(getZones());
    }
  }, [dispatch, status]);

    useEffect(() => {
      dispatch(fetchConnections());
      dispatch(fetchWorkers()); 
    }, [dispatch]);

  const handleAction = (action, rowData) => {

    if (action === 'open_queue' ) {
        dispatch(openQueue({ queue_id: rowData.queue_id, zone_name: rowData.zone_name, queue_name: rowData.queue_name }));
    }
    if (action === 'close_queue') {
        dispatch(closeQueue({ queue_id: rowData.queue_id, zone_name: rowData.zone_name, queue_name: rowData.queue_name }));
    }

    if (action === 'edit_queue') {
      setNewQueue({
        queue_name: rowData.queue_name,
        queue_status: rowData.queue_status,
        queue_id: rowData.queue_id,
        zone_name: rowData.zone_name,
        workers: rowData.workers,
        connections: rowData.connections
      });
      setShowEditModal(true); // Open the edit modal
    }

    if (action === 'delete_queue') {
      setDeleteConfirmZone(rowData.zone_name);
      setDeleteConfirmQueue(rowData.queue_name)
      setDeleteConfirmId(rowData.queue_id)
    }
  };

  const handleConnectionSelection = (connection, checked) => {
    setNewQueue((prevQueue) => {
      const updatedConnections = checked
        ? [...(prevQueue.connections || []), { connection_id: connection.connection_id, connection_name: connection.connection_name }]
        : (prevQueue.connections || []).filter((c) => c.connection_id !== connection.connection_id);
  
      return { ...prevQueue, connections: updatedConnections };
    });
  };

  const confirmDeleteQueue = () => {
    if (deleteConfirmQueue && deleteConfirmZone && deleteConfirmId) {
        dispatch(deleteQueue({ queue_id:deleteConfirmId, zone_name:deleteConfirmZone , queue_name: deleteConfirmQueue }));
    }
    setDeleteConfirmQueue('');
    setDeleteConfirmZone('');
    setDeleteConfirmId(null);
  };

  const columns = [
    {
      accessorKey: 'queue_name',
      header: 'Name',
    },
    {
      accessorKey: 'queue_status',
      header: 'Status',
    },
    {
      accessorKey: 'zone_name',
      header: 'Zone name',
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const rowData = row.original;
        const actions = [
          { label: 'Open', action: 'open_queue' },
          { label: 'Close', action: 'close_queue' },
          { label: 'Edit', action: 'edit_queue' },
          { label: 'Delete', action: 'delete_queue' },
        ];

        return (
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary" className='no-caret'>
              <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((actionItem, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleAction(actionItem.action, rowData)}
                >
                  {actionItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data: queues,
    columns,
    pageSize: 100,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const handleCreateQueueDefinition = () => {
    setNewQueue({
      queue_name: '',
      queue_status: 'Open',
      zone_name: 'Root',
      workers: [],
      connections: [],
    });
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const handleWorkerSelection = (worker: Worker, checked: boolean) => {
    setNewQueue((prevQueue) => {
      const updatedWorkers = checked
        ? [...prevQueue.workers, { worker_id: worker.worker_id, worker_name: worker.worker_name, priority: 0 }]
        : prevQueue.workers.filter((w) => w.worker_id !== worker.worker_id);
  
      return { ...prevQueue, workers: updatedWorkers };
    });
  };
  
  const handleWorkerPriorityChange = (worker_id: number, priority: number) => {
    setNewQueue((prevQueue) => {
      const updatedWorkers = prevQueue.workers.map((w) =>
        w.worker_id === worker_id ? { ...w, priority } : w
      );
  
      return { ...prevQueue, workers: updatedWorkers };
    });
  };
  
  const handleChange = (e) => {
    setNewQueue({
      ...newQueue,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveQueue = () => {
    if (newQueue.queue_id) {
        dispatch(updateQueue({queue_id:newQueue.queue_id, queue_name:newQueue.queue_name, queue_new_name: newQueue.queue_new_name, zone_new_name: newQueue.zone_new_name, queue_status:newQueue.queue_status, zone_name:newQueue.zone_name, workers:newQueue.workers, connections: newQueue.connections }));
    } else {
        dispatch(createQueue({queue_name:newQueue.queue_name,queue_status:newQueue.queue_status, zone_name: newQueue.zone_name, workers:newQueue.workers, connections: newQueue.connections }));
        handleCloseModal();
    }
    // handleCloseModal();
  };

  return (
    <>
      <Button
        className="me-2 my-2 flex-end"
        variant="primary"
        onClick={handleCreateQueueDefinition}
        style={{ marginLeft: 'auto', display: 'block' }}
      >
        New Queue
      </Button>
      {status === 'loading' && <h1>Loading...</h1>}
      {status === 'failed' && <h1>Error loading queues data.</h1>}
      {status === 'succeeded' && (
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              size: 'sm',
              hover: true,
              className:
                'phoenix-table fs-9 mb-0 border-top border-translucent',
            }}
          />
        </AdvanceTableProvider>
      )}

      {/* Create Queue Modal */}
      <Modal show={showCreateModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Queue Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter queue name"
                name="queue_name"
                value={newQueue.queue_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Queue Status</Form.Label>
              <Form.Control
                as="select"
                name="queue_status"
                value={newQueue.queue_status}
                onChange={handleChange}
              >
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Zone</Form.Label>
              <Form.Control
                as="select"
                name="zone_name"
                value={newQueue.zone_name}
                onChange={handleChange}
              >
                <option value="">Select a Zone</option> 
                {zones.map((zone: { id: string; name: string }) => (
                <option key={zone.id} value={zone.name}>
                    {zone.name}
                </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Connections</Form.Label>
                {connections.map((connection) => {
                    const isChecked = newQueue.connections?.some((c) => c.connection_id === connection.connection_id);

                    return (
                    <Form.Check
                        key={connection.connection_id}
                        type="checkbox"
                        label={connection.connection_name}
                        checked={isChecked}
                        onChange={(e) => handleConnectionSelection(connection, e.target.checked)}
                    />
                    );
                })}
            </Form.Group>

            {/* Worker Selection and Priority */}
            <Form.Group controlId="workers">
                <Form.Label>Workers</Form.Label>
                {workers.map((worker: Worker) => (
                    <div key={worker.worker_id} className="mb-2">
                    <Form.Check
                        type="checkbox"
                        label={worker.worker_name}
                        checked={newQueue.workers.some((w) => w.worker_id === worker.worker_id)}
                        onChange={(e) => handleWorkerSelection(worker, e.target.checked)}
                    />
                    {newQueue.workers?.some((w) => w.worker_id === worker.worker_id) && (
                        <Form.Control
                        type="number"
                        value={
                            newQueue.workers.find((w) => w.worker_id === worker.worker_id)?.priority || 0
                        }
                        onChange={(e) =>
                            handleWorkerPriorityChange(worker.worker_id, parseInt(e.target.value, 10))
                        }
                        placeholder="Priority"
                        />
                    )}
                    </div>
                ))}
                </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveQueue}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Queue Modal */}
      <Modal show={showEditModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Queue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Queue Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter queue name"
                name="queue_new_name"
                value={newQueue.queue_new_name ? newQueue.queue_new_name : newQueue.queue_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Queue Status</Form.Label>
              <Form.Control
                as="select"
                name="queue_status"
                value={newQueue.queue_status}
                onChange={handleChange}
              >
                <option value="Open">Open</option>
                <option value="Closed">Closed</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Zone</Form.Label>
              <Form.Control
                as="select"
                name="zone_new_name"
                value={newQueue.zone_new_name ? newQueue.zone_new_name :newQueue.zone_name}
                onChange={handleChange}
              >
                <option value="">Select a Zone</option> 
                {zones.map((zone: { id: string; name: string }) => (
                <option key={zone.id} value={zone.name}>
                    {zone.name}
                </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Connections</Form.Label>
                {connections.map((connection) => {
                    // Ensure that newQueue.connections is an array
                    const isChecked = Array.isArray(newQueue.connections) && 
                    newQueue.connections?.some((c) => c.connection_id === connection.connection_id);

                    return (
                    <Form.Check
                        key={connection.connection_id}
                        type="checkbox"
                        label={`${connection.connection_name} (${connection.connection_type})`}
                        checked={isChecked}
                        onChange={(e) => handleConnectionSelection(connection, e.target.checked)}
                    />
                    );
                })}
                </Form.Group>

            <Form.Group controlId="workers">
                <Form.Label>Workers</Form.Label>
                {workers.map((worker: Worker) => (
                    <div key={worker.worker_id} className="mb-2">
                    <Form.Check
                        type="checkbox"
                        label={worker.worker_name}
                        checked={newQueue.workers?.some((w) => w.worker_id === worker.worker_id)}
                        onChange={(e) => handleWorkerSelection(worker, e.target.checked)}
                    />
                    {newQueue.workers.some((w) => w.worker_id === worker.worker_id) && (
                        <Form.Control
                        type="number"
                        value={
                            newQueue.workers.find((w) => w.worker_id === worker.worker_id)?.priority || 0
                        }
                        onChange={(e) =>
                            handleWorkerPriorityChange(worker.worker_id, parseInt(e.target.value, 10))
                        }
                        placeholder="Priority"
                        />
                    )}
                    </div>
                ))}
                </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveQueue}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={deleteConfirmZone !== '' && deleteConfirmQueue !==''}
        onHide={() => {setDeleteConfirmZone(''); setDeleteConfirmQueue(''); setDeleteConfirmId(null)}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this queue?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {setDeleteConfirmZone(''); setDeleteConfirmQueue(''); setDeleteConfirmId(null)}}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteQueue}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListQueues;
