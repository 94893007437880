import { Row, Col, Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const Handles = ({ state, setState, onChange }) => {
  const { editable_handles = null, handles = [], type } = state;
  const isConditionsNodeType = type === 'conditions';

  // Handlers
  const deleteHandle = (id) => {
    const currentHandleIndex = handles.map((handle) => handle.id).indexOf(id);
    const mappedHandles = [...handles];

    mappedHandles.splice(currentHandleIndex, 1);

    setState({
      ...state,
      handles: mappedHandles,
    });
  };

  const createNewHandle = () => {
    const id = uuidv4();
    const newHandle = isConditionsNodeType
      ? {
          id,
          label: '',
          condition: '',
        }
      : {
          id,
          label: '',
        };
    setState({
      ...state,
      handles: [...handles, newHandle],
    });
  };

  return (
    <>
      {!!handles?.length && (
        <Form.Group className="mb-2" controlId="formHandles">
          <Form.Label>
            {isConditionsNodeType ? 'Condition Handles' : 'Handles'}
          </Form.Label>

          {handles.map((handle, index) => {
            const { id, label, condition } = handle;
            const columnSize = isConditionsNodeType
              ? 4
              : editable_handles
                ? 8
                : 12;
            const columnSizeButton = isConditionsNodeType ? 3 : 4;

            return (
              <Row className="mb-2">
                <Col lg={columnSize}>
                  <Form.Label>Label</Form.Label>
                  <Form.Control
                    key={`${id}-${index}`}
                    type="text"
                    name="label"
                    disabled={!editable_handles}
                    value={label || ''}
                    onChange={(e) => onChange(e, index, 'handles')}
                    className="mb-2"
                  />
                </Col>
                {isConditionsNodeType && (
                  <Col lg={5}>
                    <Form.Label>Condition</Form.Label>
                    <Form.Control
                      key={`${id}-${index}`}
                      type="text"
                      name="condition"
                      disabled={!editable_handles}
                      value={condition}
                      onChange={(e) => onChange(e, index, 'handles')}
                      className="mb-2"
                    />
                  </Col>
                )}
                {editable_handles && (
                  <Col lg={columnSizeButton}>
                    <Button
                      onClick={() => deleteHandle(id)}
                      variant="secondary"
                      className="w-100 mt-4"
                    >
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            );
          })}
        </Form.Group>
      )}

      {editable_handles && (
        <Row className="mb-2">
          <Col>
            <Button onClick={createNewHandle}>Add new handle</Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Handles;
