import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Tabs,
  Tab,
  Alert,
} from 'react-bootstrap';

import Parameters from './components/tabs/Parameters';

import {
  getFlatNodeInstance,
  getFlatNodeInstanceDetails,
} from '../../../../../redux/slices/instances/flatNodeInstancesSlice';

import InstancesMock from './instances-mock.json';
import Flow from './components/editors/Flow';

const InstanceViewer = () => {
  const { nodeInstanceId } = useParams();
  const dispatch = useDispatch();

  const { instanceId } = useParams();
  const [instance, setInstance] = useState({
    name: '',
    parameters: [],
    child_nodes: [],
    child_edges: [],
    selectedNode: null,
  });

  const { name, parameters } = instance;
  // const nodeWidth = 172;
  // const nodeHeight = 36;

  const setInitialNodeInstance = (response) => {
    const { payload } = response;
    const { node_instance, child_edges, child_nodes } = payload;
    const { node_instance_id: parentNodeId } = node_instance;
    const mappedNodes = child_nodes.map((node) => {
      const {
        node_id,
        node_instance_id,
        label,
        handles,
        parent_node_instance_id = null,
        status,
        activated_handles,
      } = node;
      const hasChildren = child_nodes.some(
        (node) => node.parent_node_instance_id === node_instance_id
      );
      const hasParent = child_nodes.some(
        (node) => node.node_instance_id === parent_node_instance_id
      );
      const parentId = hasParent ?? parent_node_instance_id;
      const draggable = false;

      return {
        ...node,
        id: `${node_instance_id}`,
        data: {
          label,
          handles,
          activated_handles,
          hasChildren,
          status,
          draggable,
        },
        ...(!!hasParent && { parentId: `${parent_node_instance_id}` }),
        draggable,
        // ...(!!hasChildren && {
        //   style: {
        //     backgroundColor: 'rgba(255, 0, 255, 0.2)',
        //     height: 300,
        //     width: 800,
        //   },
        // }),
        hidden: parent_node_instance_id !== parentNodeId ? true : false,
      };
    });

    const mappedEdges = child_edges.map((edge) => {
      const {
        source_node_instance_id,
        target_node_instance_id,
        source_handle,
        source_target,
      } = edge;
      return {
        ...edge,
        sourceHandle: source_handle,
        sourceTarget: source_target,
        source: `${source_node_instance_id}`,
        target: `${target_node_instance_id}`,
        type: 'smoothstep',
      };
    });

    setInstance({
      ...node_instance,
      child_nodes: mappedNodes,
      child_edges: mappedEdges,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!nodeInstanceId) return;
      try {
        const response = await dispatch(
          getFlatNodeInstance(Number(nodeInstanceId))
        );
        if (response) {
          setInitialNodeInstance(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, nodeInstanceId]);

  // const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  //   const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(
  //     () => ({})
  //   );
  //   const isHorizontal = direction === 'LR';
  //   const edgeMargin = 20;
  //   const nodeMargin = 70;
  //   dagreGraph.setGraph({
  //     rankdir: direction,
  //     nodesep: nodeMargin,
  //     edgesep: edgeMargin,
  //   });

  //   nodes.forEach((node) => {
  //     dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  //   });

  //   edges.forEach((edge) => {
  //     dagreGraph.setEdge(
  //       edge.source_node_instance_id,
  //       edge.target_node_instance_id
  //     );
  //   });

  //   dagre.layout(dagreGraph);
  //   // console.log('!!', nodes);
  //   const newNodes = nodes.map((node) => {
  //     // console.log('newnodes', node);
  //     const nodeWithPosition = dagreGraph.node(node.node_instance_id);
  //     // console.log('nodeWithPosition', nodeWithPosition);
  //     const newNode = {
  //       ...node,
  //       targetPosition: isHorizontal ? 'left' : 'top',
  //       sourcePosition: isHorizontal ? 'right' : 'bottom',
  //       // We are shifting the dagre node position (anchor=center center) to the top left
  //       // so it matches the React Flow node anchor point (top left).
  //       position: {
  //         x: nodeWithPosition.x - nodeWidth / 2,
  //         y: nodeWithPosition.y - nodeHeight / 2,
  //       },
  //     };

  //     return newNode;
  //   });
  //   // console.log('!!!', newNodes);
  //   return { nodes: newNodes, edges };
  // };

  // const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  //   nodeInstance.child_nodes,
  //   nodeInstance.child_edges
  // );

  // const showChildNodes = (parentId) => {
  //   const mappedNodes = instance.child_nodes.map((node) => {
  //     const { hidden, parent_node_instance_id } = node;

  //     if (hidden && parentId === `${parent_node_instance_id}`) {
  //       node.hidden = false;
  //     }

  //     return node;
  //   });

  //   return setInstance({ ...instance, child_nodes: mappedNodes });
  // };

  // const newInstance = {
  //   ...nodeInstance,
  //   child_nodes: layoutedNodes,
  //   child_edges: layoutedEdges,
  // };

  // const onNodeDoubleClickHandler = (e) => {
  //   const currentNodeId = e.target.getAttribute('data-id');
  //   console.log('currentNodeId', currentNodeId);
  //   // showChildNodes(currentNodeId);
  // };

  const onNodeDoubleClickHandler = useCallback(
    async (e) => {
      // const { child_nodes, child_edges } = state;
      const currentNodeId = e.target.getAttribute('data-id');
      // const currentNode: any = reactFlowInstance.getNode(currentNodeId);
      // const { node_definition_id } = currentNode;
      const currentNode =
        instance.child_nodes.find(
          (node) => node.node_instance_id === Number(currentNodeId)
        ) || {};
      const {
        data: { hasChildren },
      } = currentNode;

      const getNodeDetails = async () => {
        try {
          const response: any = await dispatch(
            getFlatNodeInstanceDetails(currentNodeId)
          );
          if (response) {
            return response.payload;
          }
        } catch (error) {
          console.error('Error fetching node definitions:', error);
        }
      };

      const currentNodeDetails = await getNodeDetails();
      setInstance({
        ...instance,
        selectedNode: { ...currentNodeDetails, id: currentNodeId, hasChildren },
      });
    },
    [instance]
  );

  return (
    <Container fluid>
      <Row className="pt-4 pb-4 justify-content-lg-center sticky-top">
        <Col lg="12">
          <h2>
            Viewing node {nodeInstanceId} - {name}{' '}
          </h2>
        </Col>
      </Row>
      <Row className="mb-4 justify-content-lg-center">
        <Col lg="12">
          <Tabs
            defaultActiveKey="definition"
            id="bash-tabs"
            className="mb-2 sticky-tabs border-bottom"
            variant="underline"
            onSelect={() => {}}
          >
            <Tab eventKey="definition" title="Definition">
              <Flow
                disabled
                state={instance}
                setState={setInstance}
                onChange={() => {}}
                onNodeDoubleClickHandler={onNodeDoubleClickHandler}
                showNodeLibrary={false}
              />
            </Tab>
            {parameters && (
              <Tab eventKey="parameters" title="Parameters">
                <Form.Group className="mb-2 w-75" controlId="form-Parameters">
                  <Form.Label className="mb-2">Current Parameters</Form.Label>
                  <Parameters
                    state={instance}
                    disabled
                    setState={() => {}}
                    onChange={() => {}}
                  />
                </Form.Group>
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default InstanceViewer;
