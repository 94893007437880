import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

// Which properties are optional?
interface NodeTypes {
  node_types: NodeType[];
  status: string | null;
  error: string | null;
}

interface NodeType {
  node_type: string;
  editable_handles: boolean | null;
}

const initialState: NodeTypes = {
  node_types: [],
  status: null,
  error: null,
};

export const getNodeTypes = createAsyncThunk<NodeTypes>(
  'nodeTypes/getNodeTypes',
  async () => {
    const response = await api.get('/lists/node_types');
    return response.data;
  }
);

const nodeTypesSlice = createSlice({
  name: 'nodeTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchWorkers
      .addCase(getNodeTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNodeTypes.fulfilled, (state, action) => {
        const { node_types } = action.payload
        state.node_types = node_types;
        state.status = 'succeeded';
      })
      .addCase(getNodeTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch node_types';
      });
  },
});

export default nodeTypesSlice.reducer;
