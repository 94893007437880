import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Dropdown, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef, RowData } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/common/SearchBox';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import EditCustomerNodeDefinition from './EditCustomerNodeDefinition';
import useAdvanceTable from 'hooks/useAdvanceTable';
import {
  fetchCustomerNodeDefinitions,
  updateCustomerNodeDefinition,
} from '../../../../redux/slices/customer_node_definitionsSlice';
import { getNodeTypes } from '../../../../redux/slices/lists/nodeTypesSlice';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate } from 'react-router-dom';

interface CustomerNodeDefinition {
  node_definition_id: number;
  type: string;
  name: string;
  created_at: string;
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    customOnClick?: () => void;
  }
}

const ListCustomerNodeDefinitions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerNodeDefinitions = useSelector(
    (state: any) => state.customerNodeDefinitions.nodes
  );
  const pagination = useSelector(
    (state: any) => state.customerNodeDefinitions.pagination
  );
  const error = useSelector(
    (state: any) => state.customerNodeDefinitions.error
  );

  const { currentPage, totalPages, totalItems, pageSize } = pagination;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageLimit, setPageLimit] = useState<number>(100);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [listOfNodeTypes, setListOfNodeTypes] = useState({ node_types: [] });

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);

  const [sortOrderBy, setSortOrderBy] = useState({
    name: '',
    isAscending: false,
  });

  const uniqueTypes: string[] = listOfNodeTypes.node_types.map(
    (item) => item.node_type
  );

  useEffect(() => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page: currentPage,
        limit: pageSize,
        type: 'All',
        search: searchTerm.length >= 3 ? searchTerm : undefined,
        sortBy: 'node_definition_id',
        sortOrder: 'asc',
      })
    );
  }, [dispatch, currentPage, searchTerm]);

  useEffect(() => {
    const fetchNodeTypes = async () => {
      try {
        const response: any = await dispatch(getNodeTypes());
        if (response) {
          const { payload } = response;
          const { node_types = [] } = payload;

          setListOfNodeTypes({ node_types });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchNodeTypes();
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setSearchTerm(value.length >= 3 ? value : '');
      }, 300)
    );
  };

  const handlePageChange = (page: number) => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page,
        type: 'All',
        limit: pageSize,
        search: searchTerm,
        sortBy: 'node_definition_id',
        sortOrder: 'asc',
      })
    );
  };

  const handlePageSizeChange = (limit: number) => {
    setPageLimit(limit);
    dispatch(
      fetchCustomerNodeDefinitions({
        page: 1,
        type: 'All',
        limit,
        search: searchTerm,
        sortBy: 'node_definition_id',
        sortOrder: 'asc',
      })
    );
  };

  const handleNodeTypeChange = (type: string) => {
    dispatch(
      fetchCustomerNodeDefinitions({
        page: 1,
        type,
        limit: pageSize,
        search: searchTerm,
        sortBy: 'node_definition_id',
        sortOrder: 'asc',
      })
    );
  };

  const handleCustomSortChange = (sortColumn: string) => {
    const hasSortOrder = sortOrderBy.isAscending;

    const sortConfig = {
      name: sortColumn,
      isAscending: !hasSortOrder,
    };

    setSortOrderBy(sortConfig);

    dispatch(
      fetchCustomerNodeDefinitions({
        page: 1,
        type: 'All',
        limit: pageLimit,
        sortBy: 'node_definition_id',
        sortColumn,
        sortOrder: hasSortOrder ? 'asc' : 'desc',
        search: searchTerm,
      })
    );
  };

  const handleAction = (action: string, rowData: CustomerNodeDefinition) => {
    const { node_definition_id } = rowData;
    if (action === 'edit_flow') {
      setSelectedNodeId(node_definition_id);
      const url = `/node_editor/${node_definition_id}`;
      window.open(url, '_blank', 'noopener, noreferrer');
    }

    if (action === 'monitor_all') {
      navigate(
        `/automation/node_instances?node_definition_id=${rowData.node_definition_id}`
      );
    }

    if (action === 'submit') {
      const maxWidth = 1200;
      const width = Math.min(maxWidth, window.innerWidth);
      const height = 800;
      const left = window.screenX + (window.innerWidth / 2) - (width / 2);
      const top = window.screenY + (window.innerHeight / 2) - (height / 2);
      window.open(
        `/node_submit/${rowData.node_definition_id}`,
        '_blank',
        `noopener, noreferrer, width=${width}, height=${height}, top=${top}, left=${left}`
      );
    }
  };

  const handleNewNodeInstance = () => {
    navigate('/automation/node_editor');
  };

  const handleCreateNodeDefinition = () => {
    const url = '/node_editor';
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const handleSave = (updatedData: CustomerNodeDefinition) => {
    const dispatch = useDispatch();
  };

  const columns: ColumnDef<CustomerNodeDefinition>[] = [
    {
      accessorKey: 'node_definition_id',
      header: 'ID',
      meta: {
        customOnClick: () => handleCustomSortChange('node_definition_id'),
      },
    },
    {
      accessorKey: 'type',
      header: 'Node Type',
      meta: {
        customOnClick: () => handleCustomSortChange('type'),
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        customOnClick: () => handleCustomSortChange('name'),
      },
    },
    {
      accessorKey: 'created_at',
      header: t('created-at'),
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString(),
      meta: {
        customOnClick: () => handleCustomSortChange('created_at'),
      },
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const rowData = row.original;
        const actions = [
          { label: 'Edit Node Definition', action: 'edit_flow' },
          { label: 'Monitor All Instances', action: 'monitor_all' },
          { label: 'Submit', action: 'submit' },
        ];

        return (
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary" size="sm">
              <FontAwesomeIcon icon={faEllipsis} className="fs-10" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((actionItem, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleAction(actionItem.action, rowData)}
                >
                  {actionItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data: customerNodeDefinitions,
    columns,
    pageSize,
    pagination: true,
    sortable: true,
    selection: false,
  });

  return (
    <Container fluid className="p-0">
      <EditCustomerNodeDefinition
        show={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        selectedNodeId={selectedNodeId}
        onSave={handleSave}
      />

      <Row className="m-0">
        <Col className="p-0">
          {error && <Alert variant="danger">{t('error-generic')}</Alert>}
          <div className="d-flex gap-2 mb-3">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-node-type"
              >
                {t('Node Type')}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {['All', ...uniqueTypes].map((nodeType) => (
                  <Dropdown.Item
                    key={nodeType}
                    onClick={() => handleNodeTypeChange(nodeType)}
                  >
                    {nodeType}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-limit">
                {t('limit')}: {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[1, 10, 25, 50, 100].map((limit) => (
                  <Dropdown.Item
                    key={limit}
                    onClick={() => handlePageSizeChange(limit)}
                  >
                    {limit}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <SearchBox
              placeholder={t('search') + '...'}
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto"
            />
            <Button className="me-2" onClick={handleCreateNodeDefinition}>
              New definition
            </Button>
          </div>
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                size: 'sm',
                hover: true,
                className:
                  'phoenix-table fs-9 mb-0 border-top border-translucent',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter
              navBtn
              pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={(page) => handlePageChange(page)}
            />
          </AdvanceTableProvider>
        </Col>
      </Row>
    </Container>
  );
};

export default ListCustomerNodeDefinitions;
