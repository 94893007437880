import { Form } from 'react-bootstrap';

const BashEditor = ({ state, onChange }) => {
  const { query } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-query">
        <Form.Label>Query</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          name="query"
          value={query}
          onChange={(e) => onChange(e, true)}
          aria-label="With textarea"
        />
      </Form.Group>
    </>
  );
};

export default BashEditor;
