import { Pagination, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from '@fortawesome/free-solid-svg-icons';

const PaginationComponent = ({
  totalPages,
  currentPage,
  onPageChange,
  limit,
  onLimitChange,
}) => {
  const handleFirst = () => onPageChange(1);
  const handleLast = () => onPageChange(totalPages);
  const handlePrev = () => onPageChange(currentPage > 1 ? currentPage - 1 : 1);
  const handleNext = () => onPageChange(currentPage < totalPages ? currentPage + 1 : totalPages);

  const handleLimitChange = (newLimit) => {
    onLimitChange(Number(newLimit));
    onPageChange(1); // Reset to the first page when limit changes
  };

  const handleDropdownSelect = (page) => {
    onPageChange(Number(page));
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      

      {/* Pagination */}
      <Pagination className="mb-0 ml-2">
        <Pagination.First onClick={handleFirst} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faAnglesLeft} />
        </Pagination.First>
        <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Pagination.Prev>

        {[...Array(5)]
          .map((_, i) => i + Math.max(1, currentPage - 2))
          .filter((page) => page <= totalPages)
          .map((page) => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}

        <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Pagination.Next>
        <Pagination.Last onClick={handleLast} disabled={currentPage === totalPages}>
          <FontAwesomeIcon icon={faAnglesRight} />
        </Pagination.Last>
      </Pagination>

      <div className="d-flex align-items-center">
      {/* Go-to Page Dropdown */}
      <Dropdown className="me-2" onSelect={handleDropdownSelect}>
        <Dropdown.Toggle variant="outline-secondary">
          Go to Page
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {[...Array(totalPages)].map((_, index) => (
            <Dropdown.Item key={index + 1} eventKey={index + 1}>
              Page {index + 1}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
          
      {/* Limit Dropdown */}
      <Dropdown as={ButtonGroup} onSelect={handleLimitChange}>
        <Dropdown.Toggle variant="outline-secondary">
          Limit: {limit}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {[25, 50, 100, 500, 1000].map((option) => (
            <Dropdown.Item key={option} eventKey={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
    </div>
  );
};

export default PaginationComponent;
