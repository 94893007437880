import { combineReducers } from 'redux';
// import userReducer from './user/userReducer'
import userReducer from './slices/userSlice';
import authReducer from './slices/auth';
import fileReducer from './slices/files';
import listenerReducer from './slices/listeners';
import userfoldersReducer from './slices/userfolders';
import rolesReducer from './slices/roles';
import devicesReducer from './slices/devices';
import configReducer from './slices/configSlice';
import influxReducer from './slices/influx';
// import dashboardSlice from "./slices/dashboardSlice";
import blocksReducer from './slices/blocksSlice';
import popupReducer from './slices/popupSlice';
import flowReducer from './slices/flowsSlice';
// import connectionReducer from './slices/databaseConnectionSlice';
import workerReducer from './slices/workersSlice';
import tablesReducer from './slices/tablesSlice';
import tableRowReducer from './slices/tableRowSlice';
import connectionsReducer from './slices/connectionsSlice';
import workflowsReducer from './slices/workflowsSlice';
import customerNodeDefinitionsReducer from './slices/customer_node_definitionsSlice';
import eventsReducer from './slices/eventsSlice';
import nodeTypesReducer from './slices/lists/nodeTypesSlice';
import zonesReducer from './slices/lists/zonesSlice';
import connectionReducer from './slices/lists/connectionsSlice';
import parameterTypesReducer from './slices/lists/parameterTypesSlice';
import reportsReducer from './slices/reportsSlice';
import flatNodeInstancesReducer from './slices/instances/flatNodeInstancesSlice';
import nodeInstancesReducer from './slices/node_instancesSlice';
import queuesReducer from './slices/lists/queuesSlice';
import nodeSubmitReducer from './slices/nodeSubmitSlice';

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  files: fileReducer,
  listeners: listenerReducer,
  userfolders: userfoldersReducer,
  roles: rolesReducer,
  devices: devicesReducer,
  config: configReducer,
  influx: influxReducer,
  // dashboard: dashboardSlice,
  blocks: blocksReducer,
  popups: popupReducer,
  flows: flowReducer,
  // connections: connectionReducer,
  workers: workerReducer,
  tables: tablesReducer,
  tableRows: tableRowReducer,
  connections: connectionsReducer,
  workflows: workflowsReducer,
  customerNodeDefinitions: customerNodeDefinitionsReducer,
  events: eventsReducer,
  listOfQueues: queuesReducer,
  listOfNodeTypes: nodeTypesReducer,
  listOfZones: zonesReducer,
  listOfConnections: connectionReducer,
  listOfParameterTypes: parameterTypesReducer,
  reports: reportsReducer,
  nodeInstances: nodeInstancesReducer,
  queues: queuesReducer,
  flatNodeInstances: flatNodeInstancesReducer,
  nodeSubmit: nodeSubmitReducer,
});

export default rootReducer;
