import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchNodeInstances } from '../../../../redux/slices/node_instancesSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons'; // Import the refresh icon
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const RefreshIconButton: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRefresh = () => {
    // Remove all parameters from the URL by navigating to the base path
    navigate('/automation/node_instances', { replace: true });

    dispatch(
        fetchNodeInstances({
          page: 1,
          limit: 100,
          sortColumn:'node_instance_id',
          sortOrder:'asc'
        })
      );
  };

  return (
    <OverlayTrigger
        placement="top" // Tooltip position (top, right, bottom, left)
        overlay={<Tooltip id="refresh-tooltip">Pressing this will clear the parameters from the URL</Tooltip>}
        >
        <button onClick={handleRefresh} className="outline-secondary rounded" style={{background:'transparent'}}>
        <FontAwesomeIcon icon={faEraser} size="lg" /> 
        </button>
    </OverlayTrigger>
  );
};
export default RefreshIconButton;
