import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const AuditTrail = ({ nodeId }) => {
  const nodes = useSelector(
    (state: any) => state?.customerNodeDefinitions.nodes
  );
  const currentNode = nodes.find(
    ({ node_definition_id }) => node_definition_id === Number(nodeId)
  );
  const { audit_trail = [] } = currentNode || {};
  const Trails = () =>
    !!audit_trail.length &&
    audit_trail.map((audit, index) => {
      const { user_name, audit_details } = audit;
      const { action, timestamp, differences = [] } = audit_details;

      // Fix css formatting
      return (
        <tr key={index}>
          <td>{timestamp}</td>
          <td>{action}</td>
          <td>{user_name}</td>
          <td>
            {differences.map((difference, index) => {
              const { field, old_value, new_value } = difference;

              return (
                <table key={index} className="mb-2">
                  <tbody>
                    <tr>
                      <td>
                        <span className="fw-bold">
                          Property changed: {field}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Old value:</span>
                        <pre className="ms-2">
                          {JSON.stringify(old_value, null, 2)}
                        </pre>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">New value:</span>
                        <pre className="ms-2">
                          {JSON.stringify(new_value, null, 2)}
                        </pre>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          </td>
        </tr>
      );
    });

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Type</th>
          <th>User</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <Trails />
      </tbody>
    </Table>
  );
};

export default AuditTrail;
