import { Form, Row, Col, Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getParameterTypes } from '../../../../../../../redux/slices/lists/parameterTypesSlice';
const Parameters = ({ state, setState, onChange, disabled = false }) => {
  const dispatch = useDispatch();
  const { parameters } = state;

  const { parameter_types } = useSelector(
    (state: any) => state?.listOfParameterTypes
  );

  useEffect(() => {
    const fetchData = async () => {
      if (parameter_types.length) return;

      try {
        const response = await dispatch(getParameterTypes());
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);


  const createNewParameter = () => {
    setState({
      ...state,
      parameters: [
        ...parameters,
        {
          name: '',
          parameter_type: parameter_types[0],
          default_expression: '',
        },
      ],
    });
  };

  const deleteParameter = (name) => {
    const currentParameterIndex = parameters
      .map((parameter) => parameter.name)
      .indexOf(name);
    const mappedParameters = [...parameters];

    mappedParameters.splice(currentParameterIndex, 1);

    setState({
      ...state,
      parameters: mappedParameters,
    });
  };

  return (
    <>
      {parameters.map((item, index) => {
        const {
          name,
          parameter_type,
          default_expression,
          runtime = false,
        } = item;

        return (
          <Row key={index} className="">
            <Col lg="4">
              <Form.Group className="mb-2">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  aria-label="parameter-name"
                  disabled={disabled}
                  name="name"
                  value={name}
                  onChange={(e) => onChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col lg="2">
              <Form.Group className="mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  aria-label="parameter-type-select"
                  disabled={disabled}
                  name="parameter_type"
                  value={parameter_type}
                  onChange={(e) => onChange(e, index)}
                >
                  {parameter_types.map((parameter, optionIndex) => {
                    const { parameter_type } = parameter;
                    return (
                      <option key={optionIndex} value={parameter_type}>
                        {parameter_type}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group>
                <Form.Label>Default Expression</Form.Label>
                <Form.Control
                  aria-label="default_expression"
                  disabled={disabled}
                  name="default_expression"
                  value={default_expression}
                  onChange={(e) => onChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col lg="1">
              <Form.Group>
                <Form.Label>Runtime</Form.Label>
                <Form.Check
                  aria-label="runtime"
                  disabled={disabled}
                  type="switch"
                  name="runtime"
                  checked={runtime}
                  value={runtime}
                  onChange={(e) => onChange(e, index)}
                />
              </Form.Group>
            </Col>
            <Col lg="1">
              <Button
                variant="secondary"
                style={{ marginTop: '23px' }}
                onClick={() => deleteParameter(name)}
              >
                Delete
              </Button>
            </Col>
          </Row>
        );
      })}
      <Row className="mt-2">
        <Col>
          <Button onClick={createNewParameter}>Add new parameter</Button>
        </Col>
      </Row>
    </>
  );
};

export default Parameters;
