// import React, { useEffect, useState } from 'react';
// import { Table, Spinner } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchNodeInstanceById } from '../../../../redux/slices/node_instancesSlice';

// const ExpandableRowContent = ({ instanceId }: { instanceId: number }) => {
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);

//   const nodeInstance = useSelector(
//     (state: any) => state.nodeInstances.selectedNodeInstance
//   );

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       await dispatch(fetchNodeInstanceById(instanceId));
//       setLoading(false);
//     };
//     fetchData();
//   }, [dispatch, instanceId]);

//   if (loading) return <Spinner animation="border" />;

//   if (!nodeInstance) return <div>No data found</div>;

//   return (
//     <div style={{ padding: '1rem' }}>
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Instance ID</th>
//             <th>Definition ID</th>
//             <th>Name</th>
//             <th>Status</th>
//             <th>Created At</th>
//             <th>Scheduled Start Datetime</th>
//             <th>Scheduled Start Timezone</th>
//             <th>Scheduled Start (Local Time)</th>
//             <th>Queue</th>
//             <th>Worker</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//             {nodeInstance?.child_nodes?.map((instance)=>{
//                 console.log('test', instance)
//                  return <tr>
//                 <td>{instance.node_instance_id}</td>
//                 <td>{instance.definition_id}</td>
//                 <td>{instance.name}</td>
//                 <td>{instance.status}</td>
//                 <td>{instance.created_at}</td>
//                 <td>{instance.scheduled_start_datetime}</td>
//                 <td>{instance.scheduled_start_timezone}</td>
//                 <td>
//                 { instance.scheduled_start_datetime ? new Date(instance.scheduled_start_datetime).toLocaleString(undefined, {timeZoneName: 'short',}): ''}</td>
//                 <td>{instance.queue_name}</td>
//                 <td>{instance.sent_to_worker_name}</td>
//                 <td>
//                 <button className="btn btn-sm btn-primary">View</button>{' '}
//                 <button className="btn btn-sm btn-danger">Cancel</button>
//                 </td>
//             </tr>}
//             )}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default ExpandableRowContent;
// import { useEffect, useState } from 'react';
// import { Table, Spinner } from 'react-bootstrap';
// import api from 'services/api';

// const ExpandableRowContent = ({ instanceId }: { instanceId: number }) => {
//   const [loading, setLoading] = useState(true);

//   const [nodeInstance, setNodeInstance] = useState<any>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       const response = await api.get(`/node_instances/${instanceId}`);
//       setNodeInstance(response.data)
//       console.log('per',response)
//       setLoading(false);
//     };
//     fetchData();
//   }, [instanceId]);


//   if (loading) return <Spinner animation="border" />;

//   if (!nodeInstance) return <div>No data found</div>;

//   return (
//     <div>
//       <Table striped bordered hover responsive>
//         <thead>
//           <tr>
//             <th>Instance ID</th>
//             <th>Definition ID</th>
//             <th>Name</th>
//             <th>Status</th>
//             <th>Created At</th>
//             <th>Scheduled Start Datetime</th>
//             <th>Scheduled Start Timezone</th>
//             <th>Scheduled Start (Local Time)</th>
//             <th>Queue</th>
//             <th>Worker</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {nodeInstance?.child_nodes?.map((instance) => (
//             <tr key={instance.node_instance_id}>
//               <td>{instance.node_instance_id}</td>
//               <td>{instance.definition_id}</td>
//               <td>{instance.name}</td>
//               <td>{instance.status}</td>
//               <td>{instance.created_at}</td>
//               <td>{instance.scheduled_start_datetime}</td>
//               <td>{instance.scheduled_start_timezone}</td>
//               <td>
//                 {instance.scheduled_start_datetime
//                   ? new Date(instance.scheduled_start_datetime).toLocaleString(undefined, {
//                       timeZoneName: 'short',
//                     })
//                   : ''}
//               </td>
//               <td>{instance.queue_name}</td>
//               <td>{instance.sent_to_worker_name}</td>
//               <td>
//                 <button className="btn btn-sm btn-primary">View</button>{' '}
//                 <button className="btn btn-sm btn-danger">Cancel</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default ExpandableRowContent;

import { useEffect, useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import api from 'services/api';
import ExpandableTableRow from './ExpandableTableRow';

interface NodeInstance {
  node_instance_id: number;
  definition_id: number;
  name: string;
  status: string;
  created_at: string;
  scheduled_start_datetime: string;
  scheduled_start_timezone: string;
  queue_name: string;
  sent_to_worker_name: string;
  child_nodes?: NodeInstance[];
  schedule: any;
}

interface ExpandableRowContentProps {
  instanceId: number;
  depth?: number;
}

const ExpandableRowContent = ({ instanceId, depth = 1 }: ExpandableRowContentProps) => {
  const [loading, setLoading] = useState(true);
  const [nodeInstance, setNodeInstance] = useState<NodeInstance | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/node_instances/${instanceId}`);
        setNodeInstance(response.data);
      } catch (error) {
        console.error('Error fetching node instance:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [instanceId]);

  if (loading) return <Spinner animation="border" />;
  if (!nodeInstance) return <div>No data found</div>;

  return (
    <div>
      <Table hover style={{ width: '100%', marginBottom: 0}} className='phoenix-table fs-9 mb-0 border-top border-translucent'>
        {/* <thead>
          <tr>
            <th >Instance ID</th>
            <th>Definition ID</th>
            <th>Name</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Scheduled Start Datetime</th>
            <th>Scheduled Start Timezone</th>
            <th>Scheduled Start (Local Time)</th>
            <th>Schedule</th>
            <th>Queue</th>
            <th>Worker</th>
          </tr>
        </thead> */}
        <tbody>
          {nodeInstance.child_nodes?.map((instance) => (
            <ExpandableTableRow key={instance.node_instance_id} instance={instance} depth={depth+1}/>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ExpandableRowContent;
