import classNames from 'classnames';
import ChatWidget from 'components/common/chat-widget/ChatWidget';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateOrAny } from 'react-redux';
import { fetchUserData } from '../redux/slices/authThunk'; // Adjust the import path for your fetchUserData action
import { useTranslation } from 'react-i18next';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MainLayout = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const userLanguage = useSelector((state: RootStateOrAny) => state.auth.userData?.language || 'nl'); // Default to 'nl' if no language is set
  useEffect(() => {
    // Only change the language if it differs from the i18n state
    if (userLanguage && i18n.language !== userLanguage) {
      console.log("changeLanguage to " + userLanguage)
      i18n.changeLanguage(userLanguage);  // Automatically set the language
    }
  }, [userLanguage, i18n]);


  const {
    config: { navbarPosition }
  } = useAppContext();

  const { contentClass, footerClass } = useMainLayoutContext();

  const token = useSelector((state: RootStateOrAny) => state.auth.token);  // Redux auth token
  const isAuthenticated = token != null;  // Assuming token existence means authenticated
  const userLoading = useSelector((state: RootStateOrAny) => state.auth.loading);  // Redux loading state
  const [isLoading, setIsLoading] = useState(true);  // Local loading state

  // Fetch user data if token is available, this runs as soon as MainLayout component is mounted
  useEffect(() => {
    if (isAuthenticated) {
      console.log("MainLayout.tsx useEffect - isAuthenticated");
      dispatch(fetchUserData());  // Dispatch to fetch user data if authenticated
    }
  }, [isAuthenticated, dispatch]);

  // Listen for changes in the loading state from Redux
  useEffect(() => {
    if (!userLoading) {
      console.log("MainLayout.tsx useEffect userLoading - !userLoading");
      setIsLoading(false);
    } else {
      console.log("MainLayout.tsx useEffect userLoading - userLoading");
      setIsLoading(true);  // Ensure local state reflects Redux loading state
    }
  }, [userLoading]);

  // If still loading, show a loading spinner or message
  if (isLoading || userLoading) {
    console.log("MainLayout.tsx - isLoading");
    return <div>Loading...</div>;  // You can replace this with a spinner component
  }

  // If not authenticated, redirect to login
  if (!isAuthenticated) {
    console.log("MainLayout.tsx - !isAuthenticated");
    return <Navigate to="/login" />;  // Redirect to login if not authenticated
  }

  console.log("MainLayout.tsx - end of all checks before last return");

  return (
    <Container fluid className="px-0">
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical loggedIn={isAuthenticated} />
      )}
      {navbarPosition === 'vertical' && <NavbarTopDefault />}
      {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
        <NavbarTopHorizontal />
      )}
      {navbarPosition === 'dual' && <NavbarDual />}

      <div className={classNames(contentClass, 'content')}>
        <Outlet />
        <Footer className={classNames(footerClass, 'position-absolute')} />
        <ChatWidget />
      </div>
    </Container>
  );
};

export default MainLayout;
