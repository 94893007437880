import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

// Which properties are optional?
interface ParameterTypes {
  parameter_types: ParameterType[];
  status: string | null;
  error: string | null;
}

interface ParameterType {
  parameter_type: string;
  parameter_type_description: string;
}

const initialState: ParameterTypes = {
  parameter_types: [],
  status: null,
  error: null,
};

export const getParameterTypes = createAsyncThunk<ParameterTypes>(
  'parameterTypes/getParameterTypes',
  async () => {
    const response = await api.get('/lists/parameter_types');
    return response.data;
  }
);

const parameterTypes = createSlice({
  name: 'parameterTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchWorkers
      .addCase(getParameterTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getParameterTypes.fulfilled, (state, action) => {
        const { parameter_types } = action.payload
        state.parameter_types = parameter_types;
        state.status = 'succeeded';
      })
      .addCase(getParameterTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch queues';
      });
  },
});

export default parameterTypes.reducer;
