import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { toast } from 'react-toastify';

// Which properties are optional?
interface Zones {
  zones: Zone[];
  status: string | null;
  error: string | null;
  auditTrail: any[]; 
}

interface Zone {
  zone_id: number;
  name: string;
  new_name?: string;
  description?: string | null;
  created_at?: string;
  updated_at?: string;
}

const initialState: Zones = {
  zones: [],
  status: null,
  error: null,
  auditTrail: [], 
};

// Fetch Zones
export const getZones = createAsyncThunk<Zones>(
  'zones/getZones',
  async () => {
    const response = await api.get('/zones');
    return response.data;
  }
);

// Create Zone
export const createZone = createAsyncThunk<Zone, { name: string; description: string },{ rejectValue: { message: string, error:string, queues:any }}>(
  'zones/createZone',
  async (zoneData, {rejectWithValue}) => {
    try{
      const response = await api.post('/zones', zoneData);
      return response.data;
    } catch(err:any){
      return rejectWithValue(err.response?.data || { message: err.message });
    }
  }
);

// Update Zone
export const updateZone = createAsyncThunk<Zone, { zone_id:number; name: string; new_name?: string; description: string }>(
  'zones/updateZone',
  async (zoneData) => {
    const response = await api.put(`/zones/${zoneData.name}`, zoneData);
    return response.data;
  }
);

// Delete Zone
export const deleteZone = createAsyncThunk<{ message: string }, string,  { rejectValue: { message: string, error:string, queues:any } }>(
  'zones/deleteZone',
  async (name, {rejectWithValue}) => {
    try{
    const response = await api.delete(`/zones/${name}`);
    return response.data;
    } catch (err:any){
      return rejectWithValue(err.response?.data || { message: err.message });
    }
  }
);

// Get Zone Audit Trail
export const getZoneAuditTrail = createAsyncThunk<any, string>(
  'zones/getZoneAuditTrail',
  async (name) => {
    const response = await api.get(`/zones/audit_trail/${name}`);
    console.log('wtf', response.data)
    return response.data;
  }
);

const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchZones
      .addCase(getZones.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getZones.fulfilled, (state, action) => {
        state.zones = action.payload.zones;
        state.status = 'succeeded';
      })
      .addCase(getZones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch zones';
      })
      
      // Handle createZone
      .addCase(createZone.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createZone.fulfilled, (state, action) => {
        state.zones.push(action.payload);
        state.status = 'succeeded';
        const toastId = "data-created-zone";
        if (!toast.isActive(toastId)) {
          toast.success(`Zone created successfully!`, { toastId });
        }
      })
      .addCase(createZone.rejected, (state, action) => {
        state.status = 'succeeded';
        state.error = action.payload?.error || 'Failed to create zone';
        const toastId = "data-failed-to-create-zone";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to create new zone! ${state.error}`, { toastId });
        }
      })

      // Handle updateZone
      .addCase(updateZone.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateZone.fulfilled, (state, action) => {
        const index = state.zones.findIndex(zone => zone.zone_id === action.payload.zone_id);
        if (index !== -1) {
          state.zones[index] = action.payload;
        }
        state.status = 'succeeded';
        const toastId = "data-updated-zone";
        if (!toast.isActive(toastId)) {
          toast.success(`Zone updated successfully!`, { toastId });
        }
      })
      .addCase(updateZone.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to update zone';
        const toastId = "data-failed-to-delete-zone";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to delete the zone. Message:${state.error}`, { toastId });
        }
      })

      // Handle deleteZone
      .addCase(deleteZone.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteZone.fulfilled, (state, action) => {
        state.zones = state.zones.filter(zone => zone.name !== action.meta.arg);
        state.status = 'succeeded';
        const toastId = "data-deleted-zone";
        if (!toast.isActive(toastId)) {
          toast.success(`Zone deleted successfully!`, { toastId });
        }
      })
      .addCase(deleteZone.rejected, (state, action) => {
        state.status = 'succeeded';
        state.error = `${action.payload?.error} ${action.payload?.queues}` 
        const toastId = "data-failed-to-delete-zone";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to delete the zone! ${state.error}`, { toastId });
        }
      })

      // Handle getZoneAuditTrail
      .addCase(getZoneAuditTrail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getZoneAuditTrail.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.auditTrail = action.payload; 
      })
      .addCase(getZoneAuditTrail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch zone audit trail';
      });
  },
});

export default zonesSlice.reducer;