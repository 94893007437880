import React, { useState  } from 'react';
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';

interface RowData {
  name?: string;
  node_instance_id?: string;
  // add other fields as needed
}

interface AdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
  expandableRowRenderer?: (row: any) => React.ReactNode;
}

const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter,
  expandableRowRenderer,
}: AdvanceTableProps) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders, getFooterGroups } = table;

  // Store a set of expanded row IDs
  const [expandedRowIds, setExpandedRowIds] = useState<Set<string>>(new Set());

  const handleRowClick = (rowId: string) => {
    setExpandedRowIds((prevExpandedRowIds) => {
      const newExpandedRowIds = new Set(prevExpandedRowIds);
      if (newExpandedRowIds.has(rowId)) {
        newExpandedRowIds.delete(rowId); 
      } else {
        newExpandedRowIds.add(rowId); 
      }
      return newExpandedRowIds;
    });
  };

  return (
    <div className="scrollbar ms-n1 ps-1" style={{ minHeight: '250px' }}>
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {getFlatHeaders().map((header) => {
              const { column, isPlaceholder, getContext, id } = header;
              const { columnDef, getToggleSortingHandler } = column;
              const { meta = {}, header: columnHeader } = columnDef;
              const { customOnClick, headerProps } = meta;

              const onClick = getToggleSortingHandler();
              const onClickHandler = customOnClick || onClick;
              const sortableClassNames = classNames(headerProps?.className, {
                sortable: column.getCanSort(),
                'sorted-desc': column.getIsSorted() === 'desc',
                'sorted-asc': column.getIsSorted() === 'asc',
              });

              return (
                <th
                  key={id}
                  {...headerProps}
                  className={sortableClassNames}
                  onClick={onClickHandler}
                >
                  {isPlaceholder
                    ? null
                    : flexRender(columnHeader, getContext())}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {getRowModel().rows.map((row) => {
            const rowData = row.original as RowData;
            const isExpandable =
              expandableRowRenderer &&
              rowData.name?.toLowerCase().includes('flow');

            return (
              <React.Fragment key={row.id}>
                <tr
                  className={classNames(rowClassName, {
                    'expandable-row': isExpandable,
                    expanded: expandedRowIds.has(row.id),
                  })}
                  onClick={() => isExpandable && handleRowClick(row.id)}
                  style={isExpandable ? { cursor: 'pointer' } : {}}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      {...cell.column.columnDef.meta?.cellProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
                {isExpandable && expandedRowIds.has(row.id) && (
                  <tr className="expandable-content-row">
                    <td colSpan={getFlatHeaders().length}>
                      {/* Ensure unique expandable content for each row */}
                      {expandableRowRenderer && expandableRowRenderer(row)}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id} className="border-0">
                {footerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.footerProps}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default AdvanceTable;

