import { Form } from 'react-bootstrap';

const WorkflowCreate = ({ state, onChange }) => {
  const { workflow_subject, workflow_category } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-workflow_subject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="workflow_subject"
          value={workflow_subject}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-workflow_category">
        <Form.Label>Category</Form.Label>
        <Form.Control
          type="text"
          name="workflow_category"
          value={workflow_category}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default WorkflowCreate;
