import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addDays, format, setHours, setMinutes, setSeconds } from "date-fns";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import "./DatePickerStyles.css"; 

interface DateRangeState {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface DatePickerProps {
  name: string; // Custom name for query params
  initialStartDate?: Date;
  initialEndDate?: Date;
  onChange: (name: string, startDate: Date, endDate: Date) => void; // Include name in the onChange callback
}

const DatePickerComponent: React.FC<DatePickerProps> = ({ name, initialStartDate, initialEndDate, onChange }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);

  // Parse initial dates from props or use defaults
  const defaultStartDate = initialStartDate;
  const defaultEndDate = initialEndDate;

  // Validate date objects to ensure they are valid before using them
  const isValidDate = (date: Date) => !isNaN(date?.getTime());

  const [dateRange, setDateRange] = useState<DateRangeState>({
    startDate: isValidDate(defaultStartDate) ? defaultStartDate : null,
    endDate: isValidDate(defaultEndDate) ? defaultEndDate : null,
    key: "selection",
  });

  const predefinedRanges = [
    { label: "Last 7 Days", days: 7 },
    { label: "Last 14 Days", days: 14 },
    { label: "Last 4 Weeks", days: 28 },
    { label: "Last 12 Weeks", days: 84 },
  ];

  const handleSelect = (ranges: { selection: DateRangeState }) => {
    setDateRange(ranges.selection);
  };

  const applyRange = () => {
    // Adjust start date to 00:00 and end date to 23:59 (local time)
    const adjustedStartDate = dateRange.startDate ? setSeconds(setMinutes(setHours(dateRange.startDate, 0), 0), 0) : null;
    const adjustedEndDate = dateRange.endDate ? setSeconds(setMinutes(setHours(dateRange.endDate, 23), 59), 59) : null;
  
    // Validate dates before formatting
    if (isValidDate(adjustedStartDate) && isValidDate(adjustedEndDate)) {
      // Format dates as local time string (not UTC)
      const localStartDate = new Date(adjustedStartDate.getTime() - adjustedStartDate.getTimezoneOffset() * 60000);
      const localEndDate = new Date(adjustedEndDate.getTime() - adjustedEndDate.getTimezoneOffset() * 60000);
  
      // Use dynamic name to construct query string
      const queryString = `?${name}_from=${localStartDate.toISOString()}&${name}_to=${localEndDate.toISOString()}`;
      window.history.replaceState(null, "", queryString);
  
      // Notify parent component with the custom name
      onChange(name, localStartDate, localEndDate);
    }

    setShowDatePicker(false);
  };  

  return (
    <div className="date-picker-container">
      <button className="set-range-btn" onClick={() => setShowDatePicker(true)}>
        {isValidDate(dateRange.startDate) && isValidDate(dateRange.endDate)
          ? `${format(dateRange.startDate, "dd/MM/yyyy")} - ${format(dateRange.endDate, "dd/MM/yyyy")}`
          : null}
      </button>

      {showDatePicker && (
        <div className="modal-overlay" onClick={() => setShowDatePicker(false)}>
          <div className="date-picker-modal" onClick={(e) => e.stopPropagation()}>
            <div className="date-picker-sidebar">
              {predefinedRanges.map(({ label, days }) => (
                <button
                  key={label}
                  className="range-btn"
                  onClick={() =>
                    setDateRange({ startDate: addDays(new Date(), -days), endDate: new Date(), key: "selection" })
                  }
                >
                  {label}
                </button>
              ))}
            </div>

            <div className="date-picker-content">
              <DateRangePicker
                ranges={[dateRange]}
                onChange={handleSelect}
                months={2}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={true}
              />
              <div className="button-container">
                <button className="apply-btn" onClick={applyRange}>
                  Set Range
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerComponent;
