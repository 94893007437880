import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

const INITIAL_SUBMIT_BODY = {
    name: '',
    queue_name: '',
    scheduled_start_datetime: '',
    scheduled_start_timezone: '',
    parameters: [],
    schedule: {}
};

// Submit a node definition
export const submitNode = createAsyncThunk(
    'nodeSubmit/submitNode',
    async (submitBody: typeof INITIAL_SUBMIT_BODY) => {
        const response = await api.post('/node_definitions_schedule', submitBody);
        return response.data;
    }
);

interface NodeSubmitState {
    status: string | null;
    error: string | null;
    rootNodeInstanceId: number | null;
}

const initialState: NodeSubmitState = {
    status: null,
    error: null,
    rootNodeInstanceId: null,
};

const nodeSubmitSlice = createSlice({
    name: 'nodeSubmit',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitNode.pending, (state) => {
                state.status = 'loading';
                state.error = null;
                state.rootNodeInstanceId = null;
            })
            .addCase(submitNode.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.rootNodeInstanceId = action.payload.rootNodeInstanceId;
                console.log('Node definition submitted successfully:', action.payload);
            })
            .addCase(submitNode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export default nodeSubmitSlice.reducer;