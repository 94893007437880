import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getZones } from '../../../../../../../redux/slices/lists/zonesSlice';

const Zones = ({ selectedValue, onChange }) => {
  const dispatch = useDispatch();
  const { zones } = useSelector((state: any) => state?.listOfZones);

  useEffect(() => {
    const fetchData = async () => {
      if (zones.length) return;

      try {
        const response = await dispatch(getZones());
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const defaultZone = selectedValue
    ? zones.find((item) => item.zone_id === selectedValue)?.name
    : null;

  return (
    <Form.Group className="mb-2" controlId="formProperties">
      <Form.Label>Default Zone</Form.Label>
      <Form.Select
        aria-label="zone-select"
        name="zone_id"
        value={selectedValue}
        onChange={(e) => onChange(e)}
      >
        {zones.map((zone, index) => {
          const { zone_id, name } = zone;

          return (
            <option key={index} value={zone_id}>
              {name}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
};

export default Zones;
