import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Define the structure of node instances
export interface NodeInstance {
  node_instance_id: number;
  type: string;
  name: string;
  config: {
    fields: {
      name: string;
      Value: any;
      editable: boolean;
    }[];
  };
  created_at?: string;
  updated_at?: string;
  node_type_fields?: NodeTypeField[];
}

export interface NodeTypeField {
  name: string;
  type: string;
  Default?: any;
  JSONPath?: string;
  ValuesApi?: string;
}

interface PaginationState {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
}

interface NodeInstanceState {
  nodes: NodeInstance[];
  pagination: PaginationState;
  status: string | null;
  search: string;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  error: string | null;
  queue_id: number | null;
  queue_name: string | null;
  sent_to_worker_id: number | null;
  sent_to_worker_name: string | null;
  nodeTypes: string[];
  selectedNodeInstance: NodeInstance[];   
}

const initialState: NodeInstanceState = {
  nodes: [],
  pagination: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 100,
  },
  status: null,
  search: '',
  sortBy: 'node_instance_id',
  sortOrder: 'asc',
  error: null,
  queue_id: null,
  queue_name: null,
  sent_to_worker_id: null,
  sent_to_worker_name: null,
  nodeTypes: [],
  selectedNodeInstance: [], 
};

export interface FetchNodeInstancesParams {
  page?: number;
  limit?: number;
  sortColumn: string;
  sortOrder: 'asc' | 'desc';
  parentOnly?: boolean;
  parentNodeInstanceId?: number | null;
  search?: string;
  queue_id?: number | null;
  queue_name?: string | null;
  sent_to_worker_id?: number | null;
  sent_to_worker_name?: string | null;
  status?: string[];
  node_definition_id?: string;
  type?: string[];
  created_at_to?: string;
  created_at_from?: string;
  scheduled_start_datetime_from?: string;
  scheduled_start_datetime_to?: string;
  scheduled_start_datetime_local_from?: string;
  scheduled_start_datetime_local_to?: string;
  node_instance_id?: string;
  scheduled_start_timezone?: string;
  name?: string;
}

export const fetchNodeInstances = createAsyncThunk(
  'nodeInstances/fetchNodeInstances',
  async (params: FetchNodeInstancesParams) => {
    const {
      page,
      limit,
      sortColumn,
      sortOrder,
      parentOnly,
      parentNodeInstanceId,
      search,
      queue_id,
      queue_name,
      sent_to_worker_id,
      sent_to_worker_name,
      status,
      node_definition_id,
      type,
      created_at_to,
      created_at_from,
      node_instance_id,
      scheduled_start_timezone,
      scheduled_start_datetime_from,
      scheduled_start_datetime_to,
      name
    } = params;

    // Construct query parameters
    const queryParams = new URLSearchParams();

    if (page !== undefined) queryParams.append('page', page.toString());
    if (limit !== undefined) queryParams.append('limit', limit.toString());
    if (sortColumn) queryParams.append('sortColumn', sortColumn);
    if (sortOrder) queryParams.append('sortOrder', sortOrder);
    if (parentOnly !== undefined)
      queryParams.append('parentOnly', parentOnly.toString());
    if (parentNodeInstanceId !== undefined)
      queryParams.append(
        'parent_node_instance_id',
        parentNodeInstanceId.toString()
      );
    if (search) queryParams.append('search', search);
    if (queue_id !== undefined)
      queryParams.append('queue_id', queue_id.toString());
    if (queue_name) queryParams.append('queue_name', queue_name);
    if (sent_to_worker_id !== undefined)
      queryParams.append('sent_to_worker_id', sent_to_worker_id.toString());
    if (sent_to_worker_name)
      queryParams.append('sent_to_worker_name', sent_to_worker_name);
    if (type && type.length > 0) {
      queryParams.append('type', type.join(','));
    }
    if (created_at_to) queryParams.append('created_at_to', created_at_to);
    if (created_at_from) queryParams.append('created_at_from', created_at_from);
    if (status && status.length > 0) {
      queryParams.append('status', status.join(','));
    }
    if (node_definition_id && node_definition_id.length > 0) {
      queryParams.append('node_definition_id', node_definition_id);
    }
    if (node_instance_id && node_instance_id.length > 0) {
      queryParams.append('node_instance_id', node_instance_id);
    }
    if (scheduled_start_timezone)
      queryParams.append('scheduled_start_timezone', scheduled_start_timezone);
    if (scheduled_start_datetime_from)
      queryParams.append(
        'scheduled_start_datetime_from',
        scheduled_start_datetime_from
      );

    if (scheduled_start_datetime_to)
      queryParams.append(
        'scheduled_start_datetime_to',
        scheduled_start_datetime_to
      );

    if (name)
      queryParams.append(
        'name',
        name
      );
  
    const response = await api.get(`/node_instances?${queryParams.toString()}`);
    return response.data;
  }
);

// Fetch Node Types
export const fetchNodeTypes = createAsyncThunk(
  'nodeInstances/fetchNodeTypes',
  async () => {
    const response = await api.get('/lists/node_types');
    console.log('types', response.data);
    return response.data.node_types;
  }
);

export const fetchNodeInstanceById = createAsyncThunk(
  'nodeInstances/fetchNodeInstanceById',
  async (node_instance_id: number) => {
    const response = await api.get(`/node_instances/${node_instance_id}`);
    return response.data;
  }
);


const nodeInstancesSlice = createSlice({
  name: 'nodeInstances',
  initialState,
  reducers: {
    setSortOrder: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.sortOrder = action.payload.sortOrder;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNodeInstances.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchNodeInstances.fulfilled, (state, action) => {
        const { pagination, nodes } = action.payload;
        state.nodes = nodes;
        state.pagination.totalItems = pagination.total_records;
        state.pagination.totalPages = pagination.total_pages;
        state.pagination.pageSize = pagination.limit;

        state.pagination.currentPage =
          pagination.current_page > pagination.total_pages
            ? 1
            : pagination.current_page;

        state.status = 'succeeded';
      })
      .addCase(fetchNodeInstances.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(fetchNodeTypes.fulfilled, (state, action) => {
        state.nodeTypes = action.payload; 
      })
      .addCase(fetchNodeTypes.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch node types';
      })
      .addCase(fetchNodeInstanceById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.selectedNodeInstance = [];
      })
      .addCase(fetchNodeInstanceById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.selectedNodeInstance.push(action.payload);
        console.log('wat', action.payload)
        console.log('uzas',state.selectedNodeInstance)
      })
      .addCase(fetchNodeInstanceById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch node instance';
        state.selectedNodeInstance = [];
      });    
  },
});

export const { setSortOrder, setSearch } = nodeInstancesSlice.actions;

// export default nodeInstancesSlice.reducer;
export default nodeInstancesSlice.reducer;
