import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerNodeDefinitionById, updateCustomerNodeDefinition } from '../../../../redux/slices/customer_node_definitionsSlice';
import NodeTypeFieldDisplay from './NodeTypeFieldDisplay';

interface EditCustomerNodeDefinitionProps {
  show: boolean;
  onClose: () => void;
  selectedNodeId: number | null;
  onSave: (updatedData: any) => void;
}

const EditCustomerNodeDefinition: React.FC<EditCustomerNodeDefinitionProps> = ({
    show,
    onClose,
    selectedNodeId,
    onSave,
  }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fieldValues, setFieldValues] = useState<Record<string, any>>({});
  
    const customerNodeDefinition = useSelector((state: any) =>
      state.customerNodeDefinitions.nodes.find(
        (node: any) => node.node_definition_id === selectedNodeId
      )
    );
  
    useEffect(() => {
      const fetchNodeDefinition = async () => {
        if (selectedNodeId !== null) {
          setLoading(true);
          try {
            await dispatch(fetchCustomerNodeDefinitionById(selectedNodeId));
          } catch (error) {
            console.error('Failed to fetch customer node definition:', error);
          } finally {
            setLoading(false);
          }
        }
      };
  
      if (show) {
        fetchNodeDefinition();
      }
    }, [dispatch, selectedNodeId, show]);
  
    useEffect(() => {
      if (customerNodeDefinition) {
        const { config, node_type_fields } = customerNodeDefinition;
  
        const fieldsConfig = config?.fields || [];
        const typeFields = node_type_fields || [];
  
        const initialFieldValues = typeFields.reduce((acc: Record<string, any>, field: any) => {
          const matchingConfigField = fieldsConfig.find((f: any) => f.name === field.name);
          acc[field.name] = matchingConfigField?.Value ?? field.Default ?? null;
          return acc;
        }, {});
  
        setFieldValues(initialFieldValues);
      }
    }, [customerNodeDefinition]);
  
    const handleFieldChange = (fieldName: string, value: any) => {
      setFieldValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
    };
  
    const handleSave = async () => {
      if (selectedNodeId !== null && customerNodeDefinition) {
        const { type, name, config } = customerNodeDefinition;
  
        const updatedConfig = {
          fields: Object.entries(fieldValues).map(([fieldName, fieldValue]) => {
            const originalField = config?.fields?.find((f: any) => f.name === fieldName);
            return {
              name: fieldName,
              Value: fieldValue,
              editable: originalField?.editable ?? false,
            };
          }),
        };
  
        const updatedData = {
          type,
          name,
          config: updatedConfig,
        };
  
        try {
          await dispatch(updateCustomerNodeDefinition({ 
            ...updatedData, 
            node_definition_id: selectedNodeId 
          }));
          onSave(updatedData);
          onClose();
        } catch (error) {
          console.error('Failed to save customer node definition:', error);
        }
      }
    };
  
    return (
      <Modal show={show} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer Node Definition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading...</p>
          ) : customerNodeDefinition ? (
            <>
              <Form>
                <Form.Group controlId="formNodeId">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={customerNodeDefinition.node_definition_id}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="formNodeName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={customerNodeDefinition.name || ''}
                    readOnly
                  />
                </Form.Group>
              </Form>
              <hr />
              <h5>Node Type Fields</h5>
              {customerNodeDefinition.node_type_fields &&
              customerNodeDefinition.node_type_fields.length > 0 ? (
                customerNodeDefinition.node_type_fields.map((field: any, index: number) => (
                  <NodeTypeFieldDisplay
                    key={index}
                    field={{
                      ...field,
                      Value: fieldValues[field.name],
                    }}
                    onFieldChange={handleFieldChange}
                  />
                ))
              ) : (
                <p>No node type fields available.</p>
              )}
              {/* <hr />
              <h5>Debug Information</h5>
              <pre
                style={{
                  background: '#f8f9fa',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              >
                {JSON.stringify(fieldValues, null, 2)}
              </pre> */}
            </>
          ) : (
            <p>No data available for the selected node.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={selectedNodeId === null || loading}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  export default EditCustomerNodeDefinition;
  