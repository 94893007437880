import React from 'react';
import { Form } from 'react-bootstrap';

interface ConnectionSQLPlusProps {
    connectionData: any;
    handleParameterChange: (key: string, value: any) => void;
}

const ConnectionSQLPlus: React.FC<ConnectionSQLPlusProps> = ({ connectionData, handleParameterChange }) => {
    return (
        <>            
            <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.username || ''}
                    onChange={(e) => handleParameterChange('username', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="connection_alias">
                <Form.Label>Connection Alias</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.connection_alias || ''}
                    onChange={(e) => handleParameterChange('connection_alias', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="privilege">
                <Form.Label>Privilege</Form.Label>
                <Form.Control
                    type="text"
                    value={connectionData.connection_parameters.privilege || ''}
                    onChange={(e) => handleParameterChange('privilege', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    value={connectionData.connection_parameters.password || ''}
                    onChange={(e) => handleParameterChange('password', e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="set_statements">
                <Form.Label>Set Statements</Form.Label>
                <Form.Control
                    as='textarea'
                    value={connectionData.connection_parameters.set_statements || ''}
                    onChange={(e) => handleParameterChange('set_statements', e.target.value)}
                />
            </Form.Group>
        </>
    );
};

export default ConnectionSQLPlus;
