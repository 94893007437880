import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getZones, updateZone, deleteZone, createZone, getZoneAuditTrail } from '../../../../redux/slices/lists/zonesSlice';
import Button from 'components/base/Button';
import { Modal, Form, Dropdown, Table, Tabs, Tab} from 'react-bootstrap';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import useAdvanceTable from 'hooks/useAdvanceTable'; 
import './dropdownStyle.css'; 
const ListZones = () => {
  const dispatch = useDispatch();
  const { zones, status } = useSelector((state: any) => state.listOfZones);
  const [deleteConfirmZoneName, setDeleteConfirmZoneName] = useState(null);
  const [key, setKey] = useState('zone'); 
  const auditTrail = useSelector((state:any) => state.listOfZones.auditTrail); 

  // Modal state
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [newZone, setNewZone] = useState<any>({
    name: '',
    new_name:'',
    description: '',
  });


  useEffect(() => {
    if (key === 'audit') {
      dispatch(getZoneAuditTrail(newZone.name));
    }
  }, [key, dispatch]);

  useEffect(() => {
    if (status === null) {
      dispatch(getZones());
    }
  }, [dispatch, status]);

  const handleAction = (action, rowData) => {
    if (action === 'edit_zone') {
      setNewZone({
        name: rowData.name,
        description: rowData.description,
        zone_id: rowData.zone_id,
      });
      setKey('zone'); 
      setShowEditModal(true); 
    }

    if (action === 'delete_zone') {
        setDeleteConfirmZoneName(rowData.name);
    }
  };

  const confirmDeleteZone = () => {
    if (deleteConfirmZoneName) {
      dispatch(deleteZone(deleteConfirmZoneName));
    }
    setDeleteConfirmZoneName(null);
  };

  const columns = [
    {
      accessorKey: 'zone_id',
      header: 'Zone Id',
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const rowData = row.original;
        const actions = [
          { label: 'Edit', action: 'edit_zone' },
          { label: 'Delete', action: 'delete_zone' },
        ];

        return (
          <Dropdown>
            <Dropdown.Toggle variant="phoenix-secondary" className="no-caret">
              <FontAwesomeIcon icon={faEllipsis}  className="fs-10" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {actions.map((actionItem, index) => (
                <Dropdown.Item key={index} onClick={() => handleAction(actionItem.action, rowData)}>
                  {actionItem.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  const table = useAdvanceTable({
    data: zones,
    columns,
    pageSize: 100,
    pagination: true,
    sortable: true,
    selection: false,
  });

  const handleCreateZoneDefinition = () => {
    setNewZone({
      name: '',
      description: '',
    });
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const handleChange = (e) => {
    setNewZone({
      ...newZone,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveZone = () => {
    if (newZone.zone_id) {
      dispatch(updateZone({ zone_id: newZone.zone_id, name: newZone.name, new_name:newZone.new_name, description: newZone.description }));
    } else {
      dispatch(createZone({ name: newZone.name, description: newZone.description }));
    }
    handleCloseModal();
  };

  return (
    <>
      <Button
        className="me-2 my-2 flex-end"
        variant="primary"
        onClick={handleCreateZoneDefinition}
        style={{ marginLeft: 'auto', display: 'block' }}
      >
        New Zone
      </Button>
      {status === 'loading' && <h1>Loading...</h1>}
      {status === 'failed' && <h1>Error loading zones data.</h1>}
      {status === 'succeeded' && (
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              size: 'sm',
              hover: true,
              className: 'phoenix-table fs-9 mb-0 border-top border-translucent',
            }}
          />
        </AdvanceTableProvider>
      )}

      {/* Create Zone Modal */}
      <Modal show={showCreateModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Zone</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Zone Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter zone name"
                name="name"
                value={newZone.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter zone description"
                name="description"
                value={newZone.description}
                onChange={handleChange}
                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveZone}>
            Save Zone
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Zone Modal */}
      <Modal show={showEditModal} onHide={handleCloseModal} className='modal-lg'
      >
      <Modal.Header closeButton>
        <Modal.Title>Edit Zone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="zone-audit-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="zone" title="Zone">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Zone Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zone name"
                  name="new_name"
                  value={newZone.new_name ? newZone.new_name : newZone.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter zone description"
                  name="description"
                  value={newZone.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Tab>
          <Tab eventKey="audit" title="Audit Trail">
            {/* Display audit trail in a table */}
            {auditTrail && auditTrail?.audit_trail?.length > 0 ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Timestamp</th>
                    <th>Type</th>
                    <th>User</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {auditTrail?.audit_trail?.map((audit, index) => (
                    <tr key={index}>
                      <td>{audit.created_at}</td>
                      <td>{audit.audit_details.action}</td>
                      <td>{audit.user_name}</td>
                      <td><b>Previous state:</b> <br></br> Name: {audit.audit_details.previous_state?.name} <br></br> Description: {audit.audit_details.previous_state?.description}  <br></br> <b> New State:</b> <br></br> Name: {audit.audit_details.new_state?.name} <br></br> Description: {audit.audit_details.new_state?.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p>No audit trail available</p>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveZone}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteConfirmZoneName !== null} onHide={() => setDeleteConfirmZoneName(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this zone?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteConfirmZoneName(null)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteZone}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListZones;
