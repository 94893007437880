import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

//
import Handles from '../ui/Handles';

const Bash = ({ state, onChange }) => {
  const { script, run_as_user, nr_of_lines_to_load } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-script">
        <Form.Label>Script</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          name="script"
          value={script}
          onChange={(e) => onChange(e, true)}
          aria-label="With textarea"
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-run_as_user">
        <Form.Label>Run as user</Form.Label>
        <Form.Control
          type="text"
          name="run_as_user"
          value={run_as_user}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group
        className="mb-2 w-25"
        controlId="formNode-nr_of_lines_to_load"
      >
        <Form.Label>Number of lines to load</Form.Label>
        <Form.Control
          type="text"
          name="nr_of_lines_to_load"
          value={nr_of_lines_to_load}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default Bash;
