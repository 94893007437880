import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center" style={{ minHeight: 150 }}>
        <h1>Norscom Automation Platform</h1>
      </div>
      <div>
        <Row xs={1} md={3} className="g-4">
          <Col>
            <Card as={Link} to="/inbox" className="text-decoration-none">
              <Card.Body>
                <Card.Title>Inbox</Card.Title>
                <Card.Text>
                  {t('View and manage your inbox')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card as={Link} to="/automation/node-instances" className="text-decoration-none">
              <Card.Body>
                <Card.Title>Node Instances</Card.Title>
                <Card.Text>
                  {t('View and manage your node instances')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card as={Link} to="/automation/node_definitions" className="text-decoration-none">
              <Card.Body>
                <Card.Title>Node Definitions</Card.Title>
                <Card.Text>
                  {t('View and maintain all node definitions')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Homepage;
