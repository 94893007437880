import { useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import classNames from 'classnames';
import usePagination from 'hooks/usePagination';

interface AdvanceTableFooterProps {
  className?: string;
  pagination?: boolean;
  navBtn?: boolean;
  showViewAllBtn?: boolean;
  currentPage?: number; // External current page (1-based)
  totalPages?: number; // External total pages
  totalItems?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void; // External page change handler
}

const AdvanceTableFooter = ({
  className,
  pagination,
  navBtn,
  showViewAllBtn = true,
  currentPage,
  totalPages,
  totalItems,
  pageSize,
  onPageChange,
}: AdvanceTableFooterProps) => {
  const {
    // setPageSize,
    getPaginationRowModel,
    getPrePaginationRowModel,
    getState,
  } = useAdvanceTableContext();

  // Derive effective current page and total pages
  const effectivePageIndex = currentPage !== undefined ? currentPage - 1 : 0;
  const effectiveTotalPages = totalPages !== undefined ? totalPages : 1;

  const totalPageCount = totalPages;
  const maxButtonCount = 5;
  const [isCurrentPage, setCurrentPage] = useState(1);

  const { visiblePaginationItems, hasNextEllipsis, hasPrevEllipsis } =
    usePagination({
      currentPageNo: isCurrentPage,
      totalPage: totalPageCount,
      maxPaginationButtonCount: maxButtonCount
    });

    const handlePageChange = (page: number) => {
      if (onPageChange) {
        setCurrentPage(page);
        onPageChange(page); // Convert to 1-based index
      }
    };    

  return (
    <Row className={classNames(className, 'align-items-center py-1')}>
      <Col className="d-flex fs-9">
        <p className="mb-0 d-none d-sm-block me-3 fw-semi-bold text-900">
          {pageSize * effectivePageIndex + 1} to{' '}
          {Math.min(pageSize * (effectivePageIndex + 1), totalItems)}{' '}
          <span className="text-600">items of</span> {totalItems}
        </p>
      </Col>
      {pagination && (
        <Col xs="auto">
          <Pagination>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {hasPrevEllipsis && (
          <Pagination.Ellipsis
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - maxButtonCount))}
          />
        )}

        {visiblePaginationItems.map((page) => (
          <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => handlePageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}

        {hasNextEllipsis && (
          <Pagination.Ellipsis
            onClick={() => setCurrentPage((prev) => Math.min(totalPageCount, prev + maxButtonCount))}
          />
        )}

        <Pagination.Next
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPageCount}
        />
        <Pagination.Last
          onClick={() => handlePageChange(totalPageCount)}
          disabled={currentPage === totalPageCount}
        />
      </Pagination>
        </Col>
      )}
    </Row>
  );
};


export default AdvanceTableFooter;
