import App from 'App';
import AuthCallback from 'components/modules/auth/callback';
import MainLayout from 'layouts/MainLayout';

// Commented for component cleanup
// Check for unused imports etc
// import ListBlocks from 'pages/apps/platform/blocks/listBlocks';
// import PopupEditBlock from 'pages/apps/platform/blocks/popupEditBlock';
// import FlowInstanceViewer from 'pages/apps/platform/flows/flows_instances/flowInstanceViewer';
// import ListFlowInstances from 'pages/apps/platform/flows/flows_instances/listFlowInstances';
// import ListFlows from 'pages/apps/platform/flows/listFlows';
// import NcpDescription from 'pages/apps/platform/ncp-description';
// import Datasources from 'pages/apps/platform/scheduler/Datasources';
// import FlowEditor from 'pages/apps/platform/scheduler/FlowEditor';
// import TaskDefinitions from 'pages/apps/platform/scheduler/TaskDefinitions';
// import TaskInstances from 'pages/apps/platform/scheduler/TaskInstances';
// import WorkflowDefinitions from 'pages/apps/platform/scheduler/WorkflowDefinitions';
// import WorkflowInstances from 'pages/apps/platform/scheduler/WorkflowInstances';
// import WorkflowTiming from 'pages/apps/platform/scheduler/WorkflowTiming';
// import CardForgotPassword from 'pages/pages/authentication/card/ForgotPassword';
// import CardLockScreen from 'pages/pages/authentication/card/LockScreen';
// import CardResetPassword from 'pages/pages/authentication/card/ResetPassword';
// import CardSignIn from 'pages/pages/authentication/card/SignIn';
// import CardSignOut from 'pages/pages/authentication/card/SignOut';
// import CardSignUp from 'pages/pages/authentication/card/SignUp';
// import CardTwoFA from 'pages/pages/authentication/card/TwoFA';
// import SplitForgotPassword from 'pages/pages/authentication/split/ForgotPassword';
// import SplitLockScreen from 'pages/pages/authentication/split/LockScreen';
// import SplitResetPassword from 'pages/pages/authentication/split/ResetPassword';
// import SplitSignIn from 'pages/pages/authentication/split/SignIn';
// import SplitSignOut from 'pages/pages/authentication/split/SignOut';
// import SplitSignUp from 'pages/pages/authentication/split/SignUp';
// import SplitTwoFA from 'pages/pages/authentication/split/TwoFA';
// import Alternate from 'pages/pages/landing/Alternate';
// import Default from 'pages/pages/landing/Default';

import ListConfig from 'pages/apps/platform/config/listConfig';
import ListConnections from 'pages/apps/platform/connections/listConnections';
import Documentation from 'pages/apps/platform/documentation/Documentation';
import ListRoles from 'pages/apps/platform/roles/listRoles';
import ListTables from 'pages/apps/platform/tables/listTables';
import ListUsers from 'pages/apps/platform/users/listUsers';
import ListWorkers from 'pages/apps/platform/workers/listWorkers';
import Error403 from 'pages/error/Error403';
import Error404 from 'pages/error/Error404';
import Error500 from 'pages/error/Error500';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
import SimpleLockScreen from 'pages/pages/authentication/simple/LockScreen';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleSignUp from 'pages/pages/authentication/simple/SignUp';
import SimpleTwoFA from 'pages/pages/authentication/simple/TwoFA';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import WorkflowDetailedView from 'pages/apps/platform/workflows/WorkflowDetailedView';
import ListAuditTrail from 'pages/apps/platform/audit/ListAuditTrail';
import ListCustomerNodeDefinitions from 'pages/apps/platform/customer_node_definitions/listCustomerNodeDefinitions';
import NodeDefinitionEditor from 'pages/apps/platform/customer_node_definitions/node_definition_editor/NodeDefinitionEditor';
import NodeInstances from 'pages/apps/platform/list_node_instances/nodeInstances';
import CreateNodeDefinitionEditor from 'pages/apps/platform/customer_node_definitions/node_definition_editor/CreateNodeDefinitionEditor';
import ListQueues from 'pages/apps/platform/queues/listQueues';
import InstanceViewer from 'pages/apps/platform/customer_node_definitions/node_definition_editor/InstanceViewer';

import ListEvents from 'pages/apps/platform/events/listEvents';
import ListReportDefinitions from 'pages/apps/platform/reports/listReportDefinitions';
import ReportRunner from 'pages/apps/platform/reports/ReportRunner';
import ListZones from 'pages/apps/platform/zones/listZones';

import { ReactFlowProvider } from 'reactflow';
import NodeSubmit from 'pages/apps/platform/submit/NodeSubmit';
import Homepage from 'pages/apps/platform/homepage';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          // <Provider store={store}>
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
          // </Provider>
        ),
        children: [
          {
            index: true,
            element: (
              <MainLayoutProvider>
                <Homepage />
              </MainLayoutProvider>
            ),
          },
          {
            path: '/documentation',
            element: (
              <MainLayoutProvider>
                <Documentation />
              </MainLayoutProvider>
            ),
          },
          {
            path: 'inbox/:workflowId?',
            element: (
              <MainLayoutProvider>
                <WorkflowDetailedView />
              </MainLayoutProvider>
            ),
          },
          {
            path: '/audittrail',
            element: <ListAuditTrail />,
          },
          {
            path: '/configuration/',
            children: [
              {
                path: 'users',
                element: <ListUsers />,
              },
              {
                path: 'roles',
                element: <ListRoles />,
              },
              {
                path: 'configitems',
                element: <ListConfig />,
              },
              {
                path: 'tables',
                element: <ListTables />,
              },
              {
                path: 'connections',
                element: <ListConnections />,
              },
              {
                path: 'workers',
                element: <ListWorkers />,
              },
              {
                path: 'queues',
                element: <ListQueues />,
              },
              {
                path: 'zones',
                element: <ListZones />,
              },
            ],
          },
          {
            path: '/automation/',
            children: [
              {
                path: 'node_definitions',
                element: <ListCustomerNodeDefinitions />,
              },
              {
                path: 'events',
                element: <ListEvents />,
              },
              {
                path: 'node_instances',
                element: <NodeInstances />
              },
            ],
          },
          {
            path: '/reports/',
            children: [
              {
                path: 'definitions',
                element: <ListReportDefinitions />,
              },
              {
                path: 'run/:rd_id',
                element: <ReportRunner />
              }
            ]
          }
        ],
      },
      {
        path: 'node_editor/:nodeId',
        element: (
          <ReactFlowProvider>
            <NodeDefinitionEditor />
          </ReactFlowProvider>
        ),
      },
        {
        path: 'instance_viewer/:nodeInstanceId',
        element: (
          <ReactFlowProvider>
            <InstanceViewer />
          </ReactFlowProvider>
        ),
      },
      {
        path: 'node_editor',
        element: <CreateNodeDefinitionEditor />,
      },
      {
        path: 'node_submit/:node_definition_id',
        element: (
          <ReactFlowProvider>
            <NodeSubmit />
          </ReactFlowProvider>
        ),
      },
      {
        path: '/login',
        element: <SimpleSignIn />,
      },
      {
        path: '/callback',
        element: <AuthCallback />,
      },
      {
        path: 'logoff',
        element: <SimpleSignOut />,
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />,
          },
          {
            path: '403',
            element: <Error403 />,
          },
          {
            path: '500',
            element: <Error500 />,
          },
        ],
      },
      {
        path: '/pages/authentication/simple/',
        children: [
          {
            path: 'sign-in',
            element: <SimpleSignIn />,
          },
          {
            path: 'sign-up',
            element: <SimpleSignUp />,
          },
          {
            path: 'sign-out',
            element: <SimpleSignOut />,
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />,
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />,
          },
          {
            path: 'lock-screen',
            element: <SimpleLockScreen />,
          },
          {
            path: '2FA',
            element: <SimpleTwoFA />,
          },
        ],
      },
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

export default routes;
