import Queues from '../ui/Queues';
import Handles from '../ui/Handles';
import Zones from '../ui/Zones';

const Properties = ({ state, setState, onChange, onArrayChange }) => {
  const { default_queue_name, zone_id } = state;

  return (
    <>
      <Queues selectedValue={default_queue_name} onChange={onChange} />
      <Zones selectedValue={zone_id} onChange={onChange} />
      <Handles state={state} setState={setState} onChange={onArrayChange} />
    </>
  );
};

export default Properties;
