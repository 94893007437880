import React from "react";
import { EdgeProps, BaseEdge, Position, getSmoothStepPath } from 'reactflow';

const LoopbackEdge: React.FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, style }) => {
  const [path, labelX, labelY, offsetX, offsetY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Right,
    targetX,
    targetY,
    targetPosition: Position.Left,
    borderRadius: 0
  });

  return (
    <>
      <BaseEdge style={{ 'stroke-dasharray': 5}} id={id} path={path} />
    </>
  );
};

export default LoopbackEdge;
