import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';

// Which properties are optional?
interface NodeInstance {
  node_instance_id: number;
  parent_node_instance_id: number | null;
  node_definition_id: number;
  name: string;
  type: string;
  status: string;
  scheduled_start_datetime: string;
  scheduled_start_timezone: string;
  created_at: string;
  queue_name: string;
  worker_name: string | null;
  output: string | null;
  outputdetails: string | null;
  worker_pid: number | string | null;
  pid_start_time: string | null;
  remote_id: number | string | null;
}

interface Instance {
  node_instance: NodeInstance;
  child_nodes: any[];
  child_edges: any[];
  error: string | null;
  status: string | null;
}

const initialState = {
  instance: {
    node_instance: {
      node_instance_id: 0,
      parent_node_instance_id: null,
      node_definition_id: 0,
      name: '',
      type: '',
      status: '',
      scheduled_start_datetime: '',
      scheduled_start_timezone: '',
      created_at: '',
      queue_name: '',
      worker_name: null,
      output: null,
      outputdetails: null,
      worker_pid: null,
      pid_start_time: null,
      remote_id: null,
    },
    child_nodes: [],
    child_edges: [],
  },
  details: {},
  error: null,
  status: null,
};

export const getFlatNodeInstance = createAsyncThunk<Instance, number>(
  'nodeInstances/getFlatNodeInstance',
  async (instance_id, { rejectWithValue }) => {
    const response = await api.get(`/node_instances_flat/${instance_id}`);
    return response.data;
  }
);

export const getFlatNodeInstanceDetails = createAsyncThunk<any, number>(
  'nodeInstances/getFlatNodeInstanceDetails',
  async (instance_id, { rejectWithValue }) => {
    const response = await api.get(`/node_instances/${instance_id}`);
    return response.data;
  }
);

const flatNodeInstances = createSlice({
  name: 'flatNodeInstances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchWorkers
      .addCase(getFlatNodeInstance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFlatNodeInstance.fulfilled, (state, action) => {
        state.instance = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getFlatNodeInstance.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch instances';
      })
      .addCase(getFlatNodeInstanceDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFlatNodeInstanceDetails.fulfilled, (state, action) => {
        state.details = action.payload;
        state.status = 'succeeded';
      })
      .addCase(getFlatNodeInstanceDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch instances';
      });
  },
});

export default flatNodeInstances.reducer;
