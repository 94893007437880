import { Form } from 'react-bootstrap';

const TableRead = ({ state, onChange }) => {
  const { table, jsonpath } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-table">
        <Form.Label>Table</Form.Label>
        <Form.Control
          type="text"
          name="table"
          value={table}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-jsonpath">
        <Form.Label>JSON path</Form.Label>
        <Form.Control
          type="text"
          name="jsonpath"
          value={jsonpath}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default TableRead;
