import React from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { v4 as uuidv4 } from 'uuid'; // Import UUID function

import iconSql from '../../../../../../../../assets/img/icons/sql-database-generic.svg';
import iconSap from '../../../../../../../../assets/img/icons/sap.svg';
import iconRest from '../../../../../../../../assets/img/icons/rest.png';
import iconFile from '../../../../../../../../assets/img/icons/file.png';

const icons = {
  sql: iconSql,
  sap_abap_run: iconSap,
  rest: iconRest,
  file: iconFile,
};

const StandardNode = (node) => {
  const { type, id, data } = node;
  const {
    handles: handleFields = [],
    hasChildren,
    status,
    draggable,
    activated_handles = [],
  } = data;

  const iconname = icons[type] || iconFile; // Select icon based on type
  const nodeClass = 'editor-node';

  // Base height is the initial height of the node, without options
  const baseHeight = 28;
  const minHeight = 55; // Minimum height for the node

  const hasHandleFields = handleFields?.length;
  // Calculate the total height based on the number of handles/options
  const totalHeight = baseHeight + hasHandleFields * 27;
  const defaultClass = hasHandleFields === 1 ? 'default' : '';

  return (
    <div
      data-id={id}
      className={`editor-node ${status} ${draggable ? '' : 'nopan'}`}
      style={{ height: `${Math.max(totalHeight, minHeight)}px` }}
    >
      <div className="pe-none" data-id={id}>
        <img src={iconname} alt="icon" className="handle-icon" /> {data.label}
      </div>
      <div className="pe-none editor-node-id">ID: {id}</div>
      {!!hasChildren && <div className="pe-none">+</div>}
      <Handle type="target" position={Position.Left} />{' '}
      <div className={`handles-container pe-none ${defaultClass}`}>
        {!!hasHandleFields &&
          handleFields.map((handle, index) => {
            const isActivatedHandle = activated_handles.some(
              (itemId) => itemId === handle.id
            );

            return (
              <div className="handle-wrapper">
                <Handle
                  id={handle.id}
                  type="source"
                  position={Position.Right}
                  className={`handle ${isActivatedHandle ? 'activated' : ''}`}
                />
                <span className="handle-label me-2">{handle.label}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

StandardNode.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default StandardNode;
