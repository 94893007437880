import { Form } from 'react-bootstrap';

const SendMail = ({ state, onChange }) => {
  const { subject, mailBody, sendToAddress } = state.config || {};

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-subject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          value={subject}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-mailBody">
        <Form.Label>Mail body</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          name="mailBody"
          value={mailBody}
          onChange={(e) => onChange(e, true)}
          aria-label="With textarea"
        />
      </Form.Group>
      <Form.Group className="mb-2 w-25" controlId="formNode-sendToAddress">
        <Form.Label>Send to address</Form.Label>
        <Form.Control
          type="text"
          name="sendToAddress"
          value={sendToAddress}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>
    </>
  );
};

export default SendMail;
