import { Form } from 'react-bootstrap';
import Handles from '../ui/Handles';

const Conditions = ({ state, setState, onArrayChange }) => {
  return (
    <>
      <Form.Group className="mb-2 w-50" controlId="formNode-script">
        <Handles
          state={state}
          setState={setState}
          onChange={onArrayChange}
        />
      </Form.Group>
    </>
  );
};

export default Conditions;
