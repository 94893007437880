import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';

// API Calls
import { getNodeTypes } from '../../../../../redux/slices/lists/nodeTypesSlice';
import { getZones } from '../../../../../redux/slices/lists/zonesSlice';
import { createCustomerNodeDefinition } from '../../../../../redux/slices/customer_node_definitionsSlice';

// Interface
import { NewNode } from '../../../../../interfaces/nodeDefinitions';

const INITIAL_NEW_NODE = {
  name: '',
  zone_id: null,
  type: '',
};

// Global handler
const handleCancel = (e) => {
  e.preventDefault();
  window.close();
};

// Create new node definition flow
const CreateNodeDefinitionEditor = () => {
  const [listOfNodeTypes, setListOfNodeTypes] = useState({ node_types: [] });
  const [listOfZones, setListOfZones] = useState({ zones: [] });
  const [state, setState] = useState<NewNode>(INITIAL_NEW_NODE);
  const [showError, setShowError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNodeTypes = async () => {
      try {
        const response: any = await dispatch(getNodeTypes());
        if (response) {
          const { payload } = response;
          const { node_types = [] } = payload;

          setListOfNodeTypes({ node_types });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchZones = async () => {
      try {
        const response: any = await dispatch(getZones());
        if (response) {
          const { payload } = response;
          const { zones = [] } = payload;
          const [firstZone] = zones;
          const { zone_id } = firstZone;

          setListOfZones({ zones });
          setState({ ...state, zone_id });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchNodeTypes();
    fetchZones();
  }, []);

  const AlertDialog = ({ error, setError }) => {
    if (!showError) return null;
    return (
      <>
        <Alert
          className="mt-4"
          onClose={() => setError(null)}
          variant="soft-danger"
          dismissible
        >
          <Alert.Heading>An error has occurred!</Alert.Heading>
          <p>{showError}</p>
        </Alert>
      </>
    );
  };

  const handleCreateNodeDefinition = async (e) => {
    e.preventDefault();
    try {
      const response: any = await dispatch(createCustomerNodeDefinition(state));
      if (response) {
        if (response?.payload?.error) {
          return setShowError(response?.payload?.error);
        }

        const { node_definition_id } = response?.payload;
        const url = `/node_editor/${node_definition_id}`;

        return navigate(url);
      }
    } catch (error) {
      console.error('Creation failed:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    return setState({ ...state, [name]: value });
  };

  const { node_types } = listOfNodeTypes;
  const { zones } = listOfZones;
  const [firstZone = {}] = zones;
  const { zone_id: defaultZone } = firstZone;

  return (
    <Container fluid>
      <AlertDialog error={showError} setError={setShowError} />

      <Form>
        <Row className="mt-4 mb-4 justify-content-lg-center">
          <Col lg="10">
            <h2>Create new node definition</h2>
          </Col>
        </Row>
        <Row className="mt-4 mb-4 justify-content-lg-center">
          <Col lg="10">
            <Form.Group className="mb-2 w-25" controlId="formNodeName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                aria-label="Select node name"
                type="text"
                name="name"
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
            <Form.Group className="mb-2 w-25" controlId="formNodeType">
              <Form.Label>Type</Form.Label>
              <Form.Select
                aria-label="Select node type"
                name="type"
                onChange={(e) => handleChange(e)}
              >
                <option>Select type</option>
                {node_types.map((node, index) => {
                  const { node_type } = node;
                  return (
                    <option key={`${node_type}=${index}`} value={node_type}>
                      {node_type}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2 w-25" controlId="formZone">
              <Form.Label>Zone</Form.Label>
              <Form.Select
                aria-label="Select zone"
                name="zone_id"
                onChange={(e) => handleChange(e)}
                value={state.zone_id || defaultZone}
              >
                <option>Select zone</option>
                {zones.map((node, index) => {
                  const { name, zone_id } = node;
                  return (
                    <option key={`${zone_id}=${index}`} value={zone_id}>
                      {name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="pt-4 justify-content-lg-center border-top">
          <Col lg="10">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="ms-2"
              onClick={handleCreateNodeDefinition}
              type="submit"
              variant="primary"
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default CreateNodeDefinitionEditor;
