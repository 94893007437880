import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Define the structure of report definitions
export interface ReportDefinition {
    rd_id: number;
    name: string;
    description?: string;
    query: string;
    parameters?: any[]; // Optional array for query parameters
    created_at?: string;
    updated_at?: string;
    zone_id: number;
    audit_trail?: any[];
}

interface PaginationState {
    totalItems: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
}

interface ReportsState {
    reportDefinitions: ReportDefinition[]; // The actual data
    selectedReport: ReportDefinition | null; // Include selected report
    pagination: PaginationState; // Pagination-related information
    status: string | null; // Loading or error state
    search: string; // Search term
    sortBy: string; // Field to sort by
    sortOrder: 'asc' | 'desc'; // Sorting direction
    error: string | null; // Error message
}

const initialState: ReportsState = {
    reportDefinitions: [],
    selectedReport: null,
    pagination: {
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
    },
    status: null,
    search: '',
    sortBy: 'rd_id', // Default sort field
    sortOrder: 'asc', // Default sort order
    error: null,
};

export interface FetchParams {
    page: number;
    limit: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    search?: string;
}

// Fetch report definitions with pagination, sorting, and search
export const fetchReportDefinitions = createAsyncThunk(
    'reports/fetchReportDefinitions',
    async (params: FetchParams) => {
        const { page = 1, limit = 10, sortBy, sortOrder, search } = params;
        const response = await api.get(
            `/report_definitions?page=${page}&limit=${limit}&sortColumn=${sortBy}&sortOrder=${sortOrder}&search=${search || ''}`
        );
        return response.data;
    }
);

// Fetch a single report definition by ID
export const fetchReportDefinitionById = createAsyncThunk<
    ReportDefinition, // Expected payload
    number, // Argument type (rd_id)
    { rejectValue: string }
>(
    'reports/fetchReportDefinitionById',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/report_definitions/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue('Failed to fetch report definition');
        }
    }
);



// Create a new report definition
export const createReportDefinition = createAsyncThunk(
    'reports/createReportDefinition',
    async (definition: Omit<ReportDefinition, 'rd_id'>) => {
        const response = await api.post('/report_definitions', definition);
        return response.data;
    }
);

// Update an existing report definition
export const updateReportDefinition = createAsyncThunk(
    'reports/updateReportDefinition',
    async (definition: ReportDefinition) => {
        const response = await api.put(`/report_definitions/${definition.rd_id}`, definition);
        return response.data;
    }
);

// Delete a single report definition by ID
export const deleteReportDefinition = createAsyncThunk(
    'reports/deleteReportDefinition',
    async (rd_id: number) => {
        await api.delete(`/report_definitions/${rd_id}`);
        return rd_id;
    }
);

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        clearSelectedReport: (state) => {
            state.selectedReport = null;
        },
        setSortOrder: (state, action) => {
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportDefinitions.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchReportDefinitions.fulfilled, (state, action) => {
                const { pagination, reports } = action.payload;
                state.reportDefinitions = reports;
                state.pagination = pagination;
                state.status = 'succeeded';
            })
            .addCase(fetchReportDefinitions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
            .addCase(fetchReportDefinitionById.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchReportDefinitionById.fulfilled, (state, action) => {
                state.selectedReport = action.payload;
            })
            .addCase(fetchReportDefinitionById.rejected, (state, action) => {
                state.error = action.payload || 'Failed to fetch report definition';
            })
            .addCase(createReportDefinition.fulfilled, (state, action) => {
                state.reportDefinitions.push(action.payload);
            })
            .addCase(updateReportDefinition.fulfilled, (state, action) => {
                const index = state.reportDefinitions.findIndex(
                    (report) => report.rd_id === action.payload.definition.rd_id
                );
                if (index !== -1) {
                    state.reportDefinitions[index] = action.payload.definition;
                }
            })
            .addCase(deleteReportDefinition.fulfilled, (state, action) => {
                state.reportDefinitions = state.reportDefinitions.filter(
                    (report) => report.rd_id !== action.payload
                );
            });
    },
});

export const { setSortOrder, setSearch, clearSelectedReport } = reportsSlice.actions;

export default reportsSlice.reducer;
