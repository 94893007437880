import { Form, Row, Col, Button } from 'react-bootstrap';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const WorkflowActionCreate = ({ state, setState, onChange }) => {
  const { config } = state;
  const {
    message,
    subject,
    workflow_id,
    response_input_options = [],
    form_field,
  } = config || {};

  const createNewResponseInputOption = () => {
    setState({
      ...state,
      config: {
        ...config,
        response_input_options: [
          ...response_input_options,
          {
            id: uuidv4(),
            label: '',
          },
        ],
      },
    });
  };

  const deleteNewResponseInputOption = (id) => {
    const currentResponseInputOptionIndex = response_input_options
      .map((option) => option.id)
      .indexOf(id);
    const mappedOptions = [...response_input_options];

    mappedOptions.splice(currentResponseInputOptionIndex, 1);

    setState({
      ...state,
      config: {
        ...config,
        response_input_options: mappedOptions,
      },
    });
  };

  const updateResponseInputOption = (event, index) => {
    const { value } = event.target;

    if (index >= 0) {
      const copyItems = [...response_input_options];

      // Update property label with value
      copyItems[index] = {
        ...copyItems[index],
        label: value,
      };

      setState({
        ...state,
        config: {
          ...config,
          response_input_options: [...copyItems],
        },
      });
    }
  };

  return (
    <>
      <Form.Group className="mb-2 w-25" controlId="formNode-message">
        <Form.Label>Message</Form.Label>
        <Form.Control
          type="text"
          name="message"
          value={message}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>

      <Form.Group className="mb-2 w-25" controlId="formNode-subject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          value={subject}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>

      <Form.Group className="mb-2 w-25" controlId="formNode-workflow_id">
        <Form.Label>Workflow ID</Form.Label>
        <Form.Control
          type="text"
          name="workflow_id"
          value={workflow_id}
          onChange={(e) => onChange(e, true)}
        />
      </Form.Group>

      <Form.Group
        className="mb-2 w-25"
        controlId="formNode-response_input_options"
      >
        <Form.Label>Response input options</Form.Label>
        {response_input_options.map((option, index) => {
          const { id, label } = option;

          return (
            <div key={`${index}-${id}`} className="d-flex mb-2">
              <Form.Control
                type="text"
                name={id}
                value={label}
                onChange={(e) => updateResponseInputOption(e, index)}
                className="me-2"
              />
              <Button
                variant="secondary"
                onClick={() => deleteNewResponseInputOption(id)}
              >
                Delete
              </Button>
            </div>
          );
        })}
      </Form.Group>
      <Row className="mt-2">
        <Col>
          <Button onClick={createNewResponseInputOption}>
            Add new response input option
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default WorkflowActionCreate;
