import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../services/api';
import { toast } from 'react-toastify';

// Queue Interface
interface Queue {
  queue_id: number;
  queue_name: string;
  queue_status: string;
  zone_name: string;
  workers?: any;
  connections?: any;
}

// State Interface
interface QueuesState {
  queues: Queue[];
  status: string | null;
  error: string | null;
}
interface RejectValue {
  message: string;
  error: string;
}

const initialState: QueuesState = {
  queues: [],
  status: null,
  error: null,
};

// Fetch Queues
export const getQueues = createAsyncThunk('queues/getQueues', async () => {
  const response = await api.get('/queues');
  return response.data;
});

export const updateQueue = createAsyncThunk(
  'queues/updateQueue',
  async ({ queue_id, queue_name, queue_new_name,zone_new_name, zone_name, queue_status, workers, connections }: {
    queue_id: number;
    queue_name?: string;
    queue_new_name?: string;
    zone_name?: string;
    zone_new_name?: string;
    queue_status: string;
    workers: any;
    connections: any;
  }) => {
    // Remove undefined fields
    const requestData = {
      queue_id,
      queue_name: queue_new_name,
      queue_status,
      zone_name: zone_new_name, 
      workers,
      connections, 
    };

    // Filter out any undefined properties (e.g. zone_name or queue_name if not changing)
    const filteredRequestData = Object.fromEntries(
      Object.entries(requestData).filter(([_, v]) => v !== undefined)
    );
    console.log(filteredRequestData)
    // Perform the PUT request with the updated request data
    const response = await api.put(`/queues/${zone_name}/${queue_name}`, filteredRequestData);
    return response.data;
  }
);

// Define Thunks
export const openQueue = createAsyncThunk(
  'queues/openQueue',
  async ({ queue_id, zone_name, queue_name }: {queue_id: number; zone_name: string; queue_name: string }) => {
    await api.put(`/queues/${zone_name}/${queue_name}/open`);
    return queue_id; 
  }
);

export const closeQueue = createAsyncThunk(
  'queues/closeQueue',
  async ({ queue_id, zone_name, queue_name }: { queue_id: number; zone_name: string; queue_name: string }) => {
    await api.put(`/queues/${zone_name}/${queue_name}/close`);
    return queue_id; 
  }
);

export const deleteQueue = createAsyncThunk<
  number, 
  { queue_id: number; zone_name: string; queue_name: string }, 
  { rejectValue: RejectValue } 
>(
  'queues/deleteQueue',
  async ({ queue_id, zone_name, queue_name }, { rejectWithValue }) => {
    try {
      await api.delete(`/queues/${zone_name}/${queue_name}`);
      return queue_id; 
    } catch (err: any) {
      return rejectWithValue(err.response?.data || { error: err.message });
    }
  }
);

// Create Queue
export const createQueue = createAsyncThunk<
  Queue, 
  Omit<Queue, 'queue_id'>,
  { rejectValue: RejectValue } 
>(
  'queues/createQueue',
  async ({ queue_name, queue_status,zone_name, workers, connections}: Omit<Queue, 'queue_id'>, {rejectWithValue}
  ) => {
    try{
      const response = await api.post('/queues', { queue_name, queue_status,zone_name, workers, connections });
      return response.data;
    } catch(err:any){
      return rejectWithValue({
        message: err.message || 'Failed to create queue',
        error: err.response?.data?.error || 'Unknown error',
      });
    }
  }
);

const queuesSlice = createSlice({
  name: 'queues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle getQueues
      .addCase(getQueues.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQueues.fulfilled, (state, action) => {
        state.queues = action.payload.queues;
        state.status = 'succeeded';
      })
      .addCase(getQueues.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch queues';
      })
      // Handle updateQueue
      .addCase(updateQueue.fulfilled, (state, action) => {
        const index = state.queues.findIndex((queue) => queue.queue_id === action.payload.queue_id);
        if (index !== -1) state.queues[index] = action.payload;
          const toastId = "data-updated-queue";
          if (!toast.isActive(toastId)) {
            toast.success(`Queue updated successfully!`, { toastId });
          }
      })
      .addCase(updateQueue.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to update queue';
        const toastId = "data-failed-to-update-queue";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to update queue!`, { toastId });
        }
      })
      // Handle deleteQueue
      .addCase(deleteQueue.fulfilled, (state, action) => {
        state.queues = state.queues.filter((queue) => queue.queue_id !== action.payload);
        const toastId = "data-deleted-queue";
        if (!toast.isActive(toastId)) {
          toast.success(`Queue deleted successfully!`, { toastId });
        }
      })
      .addCase(deleteQueue.rejected, (state, action) => {
        state.error = action.payload?.error || 'Failed to delete queue';
        const toastId = "data-failed-to-delete-queue";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to delete queue! ${state.error}`, { toastId });
        }
      })
      // Handle createQueue
      .addCase(createQueue.fulfilled, (state, action) => {
        state.queues.push(action.payload);
        const toastId = "data-create-queue";
        if (!toast.isActive(toastId)) {
          toast.success(`Queue created successfully!!`, { toastId });
        }
      })
      .addCase(createQueue.rejected, (state, action) => {
        state.error = action.payload?.error || 'Failed to create queue';
        const toastId = "data-failed-to-create-queue";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to create queue! ${state.error}`, { toastId });
        }
      })
      // Handle openQueue
      .addCase(openQueue.fulfilled, (state, action) => {
        const index = state.queues.findIndex((queue) => queue.queue_id === action.payload);
        if (index !== -1) {
          state.queues[index].queue_status = 'open'; 
        }
        const toastId = "data-open-queue";
        if (!toast.isActive(toastId)) {
          toast.success(`Succcessfully changed queue status to 'Open'!`, { toastId });
        }
      })
      .addCase(openQueue.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to open queue';
        const toastId = "data-failed-to-open-queue";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to change queue status to 'Open'`, { toastId });
        }
      })
      // Handle closeQueue
      .addCase(closeQueue.fulfilled, (state, action) => {
        const index = state.queues.findIndex((queue) => queue.queue_id === action.payload);
        if (index !== -1) {
          state.queues[index].queue_status = 'closed';
        }
        const toastId = "data-close-queue";
        if (!toast.isActive(toastId)) {
          toast.success(`Successfully changed queue status to 'Open'!`, { toastId });
        }
      })
      .addCase(closeQueue.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to close queue';
        const toastId = "data-failed-to-close-queue";
        if (!toast.isActive(toastId)) {
          toast.error(`Failed to change queue status to 'Closed'!`, { toastId });
        }
      });
  },
});

export default queuesSlice.reducer;
